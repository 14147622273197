import { Component, OnInit, Inject, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiserviceService } from "../../../apiservice.service";
import { NotificationService } from "../../services/notification.service";
import { initialSharedModel, SharedModel } from "../../shared/sharedmodel/shared.model";

@Component({
  selector: "app-copy-attributes",
  templateUrl: "./copy-attributes.component.html",
  styleUrls: ["./copy-attributes.component.css"],
})
export class CopyAttributesComponent implements OnInit {
  options: { value: string; viewValue: string }[] = [];
  copyAttributesGroup: FormGroup;
  citieslist: { value: string; viewValue: string }[] = [];
  filteredCities: { value: string; viewValue: string }[] = []; // Add this property for filtered cities
  isAllSelected = false;
  sharedModel: SharedModel = { ...initialSharedModel };
  selected_city_id: any;

  @ViewChild('searchValue') searchValue!: ElementRef; // Reference to the search input

  constructor(
    public dialogRef: MatDialogRef<CopyAttributesComponent>,
    private api: ApiserviceService,
    private fb: FormBuilder,
    private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.copyAttributesGroup = this.fb.group({
      city_selection: [[]], // Initialize with an empty array
    });
    this.getCityList();
    console.log(this.api.city_id, "selected_city_id");
    this.selected_city_id = this.api.city_id;
  }

  getCityList() {
    this.api.getUmsData("country-with-city").subscribe({
      next: (res: any) => {
        this.citieslist = this.formatCityList(res.data);
        this.filteredCities = this.citieslist; // Initialize filteredCities
      },
      error: (err) => {
        console.error("Error fetching city list:", err);
      },
    });
  }

  formatCityList(data: any[]): { value: string; viewValue: string }[] {
    let cityList = [];
    data.forEach((element) => {
      cityList.push({
        value: element.place_id,
        viewValue: element.place_name,
      });
      if (element.child_data) {
        element.child_data.forEach((child) => {
          cityList.push({
            value: child.place_id,
            viewValue: `${child.place_name} (${element.place_name})`,
          });
        });
      }
    });
    return cityList;
  }

  filterCities(searchTerm: string) {
    searchTerm = searchTerm.toLowerCase(); // Normalize the search term
    this.filteredCities = this.citieslist.filter(city =>
      city.viewValue.toLowerCase().includes(searchTerm) // Filter based on the search term
    );
  }

  onCityRemoved(city: { value: string; viewValue: string }) {
    this.isAllSelected = false;
    const cities = this.copyAttributesGroup.value.city_selection as { value: string; viewValue: string }[];
    this.removeFirst(cities, city);
    this.copyAttributesGroup.get("city_selection")?.setValue(cities);
    this.checkAllSelected(false);
  }

  public removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  submit() {
    let selectedCityIds;
    let selectedAttribute;
    const selectedCities = this.copyAttributesGroup.get("city_selection")?.value || [];
    if (!this.isAllSelected) {
      selectedCityIds = selectedCities.map(
        (city: { value: number; viewValue: string }) => city.value
      );
    }
    selectedAttribute = this.data.map(
      (attributeList: { attribute_id: number }) => attributeList.attribute_id
    );
    let url = `copy-attribute-cross-db`;
    let body = {
      selected_attributes: selectedAttribute,
      target_cities: selectedCityIds,
      isCopyToAllCities: this.isAllSelected,
    };
    this.sharedModel.loader = true;
    this.api.postGmsData(url, body).subscribe({
      next: (res: any) => {
        this.sharedModel.loader = false;
        this.notify.notify(res.message, "success");
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.sharedModel.loader = false;
      },
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  selectAll(checked: boolean) {
    this.isAllSelected = checked; // Update the isAllSelected state
    if (checked) {
      const allCities = this.citieslist.map((city: any) => city); // Get all cities as objects
      this.copyAttributesGroup.get("city_selection")?.setValue(allCities);
    } else {
      this.copyAttributesGroup.get("city_selection")?.setValue([]);
    }
  }

  checkAllSelected(checked: boolean) {
    const selectedCities = this.copyAttributesGroup.get("city_selection")?.value || [];
    this.isAllSelected = selectedCities.length === this.citieslist.length; // Update isAllSelected based on selected cities
  }

  selectionChange() {
    if (this.copyAttributesGroup.value.city_selection.length == this.citieslist.length) {
      this.isAllSelected = true;
    } else {
      this.isAllSelected = false;
    }
  }

  clearSearchInput() {
    if (this.searchValue) {
      this.searchValue.nativeElement.value = ''; // Clear the input value
      this.filterCities(''); // Reset the filtered list to show all cities
    }
  }
}
