export interface Status {
  status_code: number;
  value: string;
}


export const statusList: Status[] = [
  { status_code: -1, value: 'Invalid' },
  { status_code: 1, value: 'Valid' },
  { status_code: 2, value: 'Accepted' },
  { status_code: -2, value: 'Rejected' },
  { status_code: 0, value: 'Uploaded' },
  { status_code: -3, value: 'Accept Invalid' },
  { status_code: 4, value: 'Accept Progress' }
];

export const statusSubProcess: Status[] = [
  { status_code: -1, value: 'Error' },
  { status_code: 1, value: 'Completed' },
  { status_code: 0, value: 'Uploaded' },
  { status_code: 4, value: 'Running...' }
];

export const StatusClassMap = {
  "-1": { class: "Invalid", color: "red" },
  "1": { class: "Valid", color: "blue" },
  "2": { class: "Accepted", color: "green" },
  "-2": { class: "Accept-invalid", color: "orange" },
  "0": { class: "Uploaded", color: "purple" },
  "-3": { class: "Rejected", color: "darkred" },
  "4": { class: "Accept-progress", color: "yellow" },
  "3": { class: "Validating", color: "gray" },
};
