import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-gis-primary-button',
  templateUrl: './gis-primary-button.component.html',
  styleUrls: ['./gis-primary-button.component.scss']
})
export class GisPrimaryButtonComponent {
  @Input() button_type: any;  // Input to determine the type of action (e.g., update, submit, calculation)
  @Input() button_label: any;  // Input to set the label for the button (e.g., "Submit", "Update")
  @Input() isDisabled: boolean;  // Input to control whether the button is disabled or not
  @Output() onAction = new EventEmitter<any>();  // Output event emitter to notify the parent component of any action triggered by the button

  onActions(){
    this.onAction.emit()
  }
}
