import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";

export interface TableData {
  sno: number;
  Name:string;
  columnName: string;
  dataType: string;
  jsonKey: string;
  refTable: string;
  refColName: string;
  refConditionName: string;
  required: boolean;
  action: string;
}

const ELEMENT_DATA: TableData[] = [
  {
    sno: 1,
    Name:"Name",
    columnName: "Name",
    dataType: "String",
    jsonKey: "name",
    refTable: "user",
    refColName: "user_id",
    refConditionName: "status = active",
    required: true,
    action: "Edit",
  },
  // Add more rows as needed
];

@Component({
  selector: "app-gis-form-table",
  templateUrl: "./gis-form-table.component.html",
  styleUrls: ["./gis-form-table.component.scss"],
})
export class GisFormTableComponent implements OnInit {
  @Input() dataTypeList: any;
  @Output() formDataEmitter = new EventEmitter<TableData[]>(); // Event to emit data
  displayedColumns: string[] = [
    "sno",
    "Name",
    "columnName",
    "dataType",
    "jsonKey",
    "refTable",
    "refColName",
    "refConditionName",
    "required",
    "action",
  ];
  dataSource = ELEMENT_DATA;
  options: { value: number; viewValue: string }[] = [];
  formLayerGroup: FormGroup;
  get layerFormsArray() {
    return this.formLayerGroup.get('formLayerArray') as FormArray
  }
  constructor(private fb:FormBuilder){

  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes["dataTypeList"] && changes["dataTypeList"].currentValue) {
      console.log("Received dataTypeList in child:", this.dataTypeList);
      this.transformDataType();
    }
  }
  ngOnInit() {
    this.formLayerGroup = this.fb.group({
      formLayerArray: this.fb.array([])
    })
    this.addRow()
  }
  addRow(element:any='', index:number=0, type='') {
    const  formLayerArray = this.fb.group({
      name: ['', ],
  })
    this.layerFormsArray.push(formLayerArray);
    // if(type == 'patch'){
    //   this.layerFormsArray.controls[index].patchValue({
    //     layer_name:element.layer_name,
    //     label:element.label,
    //     store_name:element.store_name,
    //     color:element.color,
    //     display:element.display,
    //     type:element.type,
    //     maxZoom:element.maxZoom,
    //     minZoom:element.minZoom,
    //     layer_status:element.layer_status
    //   })
    //   if(element.display){
    //     this.formLayerGroup.patchValue({
    //       default_layer: element.layer_name 
    //     })
    //   }
    // }
  }
  transformDataType() {
    this.dataTypeList.forEach((item) => {
      this.options.push({
        value: item.id, // Extract 'id' as value
        viewValue: item.label["1"], // Extract label at index 1 (English label) as viewValue
      });
    });
    console.log(this.options); // Check the result
  }
    emitFormData() {
    // Emit the current dataSource when called
    this.formDataEmitter.emit(this.dataSource);
  }

   // Method to add a new row
  //  addRow() {
  //   const newRow: TableData = {
  //     sno: this.dataSource.length + 1,
  //     Name:'',
  //     columnName: '',
  //     dataType: '',
  //     jsonKey: '',
  //     refTable: '',
  //     refColName: '',
  //     refConditionName: '',
  //     required: false,
  //     action: 'Edit',
  //   };
  //   this.dataSource.push(newRow);
  //   this.dataSource = [...this.dataSource];  // Trigger change detection
  // }
  // Method to remove a row by index
  removeRow(index: number) {
    if (this.dataSource.length > 1) {
      this.dataSource.splice(index, 1);
      this.dataSource = [...this.dataSource];  // Trigger change detection
    }
  }

  submit(){
    console.log(this.formLayerGroup.value);
    
  }
}
