/**
 * ManageCsvTemplateComponent is responsible for managing the CSV templates in the application.
 * It handles the table view of CSV templates, including actions such as editing and deleting templates.
 * It also provides functionalities like pagination and dynamic column selection.
 *
 * Features:
 * - Displays a table of CSV templates with configurable columns.
 * - Handles edit and delete actions for the CSV templates.
 * - Supports pagination with limit and offset.
 * - Emits events for navigation to parent components.
 *
 * Dependencies:
 * - FormControl: Provides form control mechanisms for template management.
 * - ApiserviceService: Used for fetching CSV template data and performing operations like editing and deleting.
 * - SharedModel: Common model shared across components, used for handling pagination and other shared data.
 *
 * Methods:
 * - ngOnInit: Initializes the component by fetching template data.
 * - onPageChange: Updates pagination limit and offset.
 * - deleteuploadeddata: Placeholder for delete logic to remove uploaded data.
 * - editTempDetails: Navigates to the template edit page for the selected element.
 * - goBack: Emits an event to notify the parent component for navigation purposes.
 * - changePage: Handles page changes and navigation within the component.
 * - getTemplateData: Fetches CSV template data from the server using the ApiService.
 */

import { Component, EventEmitter, Output } from "@angular/core";
import {
  initialSharedModel,
  SharedModel,
} from "../../../../../app/admin/shared/sharedmodel/shared.model";
import { ApiserviceService } from "../../../../../../src/app/apiservice.service";

@Component({
  selector: "app-manage-csv-template",
  templateUrl: "./manage-csv-template.component.html",
  styleUrls: ["./manage-csv-template.component.css"],
})
export class ManageCsvTemplateComponent {
  @Output() goBackEvent = new EventEmitter<void>();

  // Configuration object for button actions and styles
  buttonConfig = {
    action: [
      {
        action: (element: any) => this.editTempDetails(element), // Function to edit template details
        icon: "assets/icon/edit-icon.svg",
        style: {
          backgroundColor: "#fff",
        },
        class: "action-button",
      },
    ],
    query: [
      {
        action: (element: any) => this.queryIndex(element), // Function to edit template details
        icon: "assets/icon/edit-icon.svg",
        style: {
          backgroundColor: "#fff",
        },
        class: "action-button",
      },
    ],
  };

  selectedElement: any;

  constructor(private api: ApiserviceService) {}

  // Lifecycle hook that gets called when the component is initialized
  ngOnInit() {
    this.getTemplateData(); // Fetches the initial template data
  }

  // Array defining columns to be displayed in the table
  displayColumn = [
    "id",
    "file_type",
    "column_names",
    "added_by",
    "updated_by",
    "added_date",
    "updated_date",
    // "query",
    "action",
  ];

  tableData: any; // Holds the data to be displayed in the table
  sharedModel: SharedModel = { ...initialSharedModel }; // Common variable from shared model
  totalCount: number = 0; // Total count of records for pagination
  selectedData: any[]; // Array to hold selected template data
  selectedColumns: string[]; // Defines which columns to display in the table
  isAddEditCsvTemp: boolean = false; // Controls whether the Add/Edit template page is displayed
  isQueryIndex: boolean = false;

  // Handles pagination event and updates limit and offset
  onPageChange(event: any) {
    this.sharedModel.limit = event.limit; // Update limit
    this.sharedModel.offset = event.offset; // Update offset
  }

  // Placeholder for deleting uploaded data (to be implemented)
  deleteuploadeddata(element: any) {}

  // Navigates to the Add/Edit CSV template page with the selected element's details
  editTempDetails(element: any) {
    this.selectedElement = element;
    this.changePage("add_edit_csv_temp");
  }
  queryIndex(element) {
    this.selectedElement = element;
    this.changePage("query_index_edit");
  }

  // Emits an event to the parent component to navigate back
  goBack() {
    this.goBackEvent.emit();
  }

  // Placeholder for handling city selection (to be implemented)
  onCitySelect(selectedValue: any) {}

  // Placeholder for accepting or rejecting requests (to be implemented)
  acceptOrRejectRequest() {
    throw new Error("Method not implemented.");
  }

  // Handles page changes within the component (e.g., switching to Add/Edit template page)
  changePage(type: string = "") {
    if (type == "add_csv_temp") {
      this.selectedElement = null;
    }
    const pages = {
      add_csv_temp: { isAddEditCsvTemp: true, isQueryIndex: false }, // Explicitly set both
      add_edit_csv_temp: { isAddEditCsvTemp: true, isQueryIndex: false },
      query_index_edit: { isAddEditCsvTemp: false, isQueryIndex: true }, // Set isQueryIndex to true for query_index_edit
      default: { isAddEditCsvTemp: false, isQueryIndex: false },
    };

    // Select the appropriate page state or fallback to default
    const selectedPage = pages[type] || pages["default"];
    this.isAddEditCsvTemp = selectedPage.isAddEditCsvTemp;
    this.isQueryIndex = selectedPage.isQueryIndex;

    // Refetch data only when navigating to 'add_edit_csv_temp' page
    if (type === "add_edit_csv_temp" || type === "query_index_edit" || type === "default") {
      this.getTemplateData();
    }

  }

  getTemplateData() {
    let url = `csv-master?limit=${this.sharedModel.limit}&offset=${this.sharedModel.offset}`;
    if (this.sharedModel.offset == 0) url += `&is_count=true`; // Fetches total count on the first page
    this.api.getGmusData(url).subscribe({
      next: (res: any) => {
        // Assign fetched data to the table
        this.tableData = res.data.map((item: any) => {
          // Format the column_name array to a JSON string or custom format
          return {
            ...item,
            column_names: item.column_name
              ? JSON.stringify(item.column_name, null, 2)
              : "",
          };
        });
        this.totalCount = res.totalRecord; // Updates total record count
      },
      error: (err) => {
        // Error handling logic (can be expanded)
      },
    });
  }
}
