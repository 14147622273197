import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ApiserviceService } from "../../../../../../src/app/apiservice.service";
import { TableData } from "../../../shared/components/gis-form-table/gis-form-table.component";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NotificationService } from "../../../../../../src/app/admin/services/notification.service";
import { CsvTemplateRequestBody } from "../add-edit-csv-template/csvTemplateRequestBody.model";

@Component({
  selector: 'app-csvtemplate-query',
  templateUrl: './csvtemplate-query.component.html',
  styleUrls: ['./csvtemplate-query.component.scss']
})
export class CSVTemplateQueryComponent {
  @Input() editSelectionElement: any;
  @Output() goBackEvent = new EventEmitter<void>();
  tableData: TableData[] = [];
  csvTempFormGroup: FormGroup;
  isEdit: boolean = false;
  button: string = "Submit";
  constructor(
    private api: ApiserviceService,
    private fb: FormBuilder,
    private notify: NotificationService
  ) {}

  ngOnInit() {
    this.csvTempFormGroup = this.fb.group({
      csvTempArray: this.fb.array([]),
    });
    if (!this.editSelectionElement.query_index) {
      this.addRow();
      this.isEdit = false;
      this.button = "Submit";
    } else {
      this.patchFormValue();
      this.isEdit = true;
      this.button = "Update";
    }

    console.log(this.editSelectionElement);

  }

  goBack() {
    this.goBackEvent.emit(); // Emit event to notify parent component
    this.resetForm()
    this.editSelectionElement = [];
  }

  // Function to get the controls of a specific row
  getRowControls(index: number): FormGroup {
    const rows = this.csvTempFormGroup.get("csvTempArray") as FormArray;
    return rows.at(index) as FormGroup;
  }
  /**
   * patching value in form if already data have
   */
  patchFormValue() {
    this.editSelectionElement.query_index.forEach((element, index) => {
      this.addRow(element, index, "patch");
    });
  }

  /**
 * Adds a new row to the CSV template form array, initializing it with default values and validation rules.
 *
 * This method constructs a FormGroup for a new row in the CSV template and pushes it to the
 * `csvTempFormArray`. If the `type` parameter is set to "patch", it also populates the form
 * with values from the provided `element` and sets the `table_name` and `file_type`
 * in the parent form group from the `editSelectionElement`.
 *
 * @param {any} element - The data object containing values to initialize the row fields.
 *                        Defaults to an empty string if not provided.
 * @param {number} index - The index at which the new row will be added.
 *                         Defaults to 0, which may be used for sorting purposes.
 * @param {string} type - Determines the behavior of the method. If set to "patch",
 *                        it will initialize the form controls with values from the `element` object.
 *                        Defaults to an empty string, indicating a standard addition of a new row.
 *
 * @returns {void} - This method does not return a value; it updates the internal form structure.
 *
 * @example
//  * // Adding a new row with default values
 * this.addRow();
 */

addRow(element: any = "", index: number = 0, type: string = "") {
  // Create a form group for the new CSV template row
  const csvTempArray = this.createCsvTempFormGroup(index);

  // Push the new form group to the form array
  this.csvTempFormArray.push(csvTempArray);

  // If this is an edit operation (patch), populate the form group with existing data
  if (type === "patch") {
    this.populateCsvTempFormGroup(index, element);
    console.log(element);

  }
}

// Helper method to create a new form group for CSV template row
private createCsvTempFormGroup(index: number) {
  return this.fb.group({
    index_name: ["", this.getValidators()],
    index: ["", this.getValidators()],
    column: [],
    condition: [""],
  });
}
// Helper method to get validators for the fields
private getValidators() {
  return Validators.compose([
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/), // Ensure not just whitespace
    Validators.required
  ]);
}

// Helper method to populate the form group with existing data during edit
private populateCsvTempFormGroup(index: number, element: any) {
  // Remove parentheses from the index value
  const indexWithoutParentheses = element.index.replace(/[()]/g, ''); // Removes all '(' and ')' characters

  this.csvTempFormArray.controls[index].patchValue({
    index: indexWithoutParentheses, // Patch the value without parentheses
    index_name: element.index_name,
    column: element.column,
    condition: element.condition,
  });
}
  /**
   * close window without change
   */
  get csvTempFormArray() {
    return this.csvTempFormGroup.get("csvTempArray") as FormArray;
  }
  /**
   *  remove map layer if layer status is inactive
   * @param i
   * @param csvTempItem
   */
  removeRow(i, csvTempItem) {
    this.csvTempFormArray.removeAt(i);
  }


  /**
 * Submits the CSV template form data to the server for creation or update.
 *
 * This method constructs the request body with the values from the CSV template form, including
 * the file type, column names, table name, and user information. It determines whether to
 * create a new template or update an existing one based on the `isEdit` flag.
 *
 * If the form is for a new template (i.e., `isEdit` is false), it sends a POST request to create
 * the new template. If the form is for editing an existing template (i.e., `isEdit` is true),
 * it sends a PATCH request to update the existing template.
 *
 * Upon receiving a response from the server, it checks the status:
 * - If successful (status 200), it emits a notification to inform the user of the successful
 *   operation and triggers the `goBack` method to navigate back to the previous view.
 * - If unsuccessful, it displays a warning notification with the server's message.
 *
 * @returns {void} - This method does not return a value; it handles the submission process
 *                   and updates the UI based on the response.
 *
 * @throws {Error} - If an error occurs during the API request, it logs the error to the console.
 *
 */

  submit() {
    const url = `csv-master`; // Define the URL for the API endpoint

    // Transform query_index values to the desired format
    const queryIndexTransformed = this.csvTempFormArray.value.map(item => {
      const indexValues = item.index.split(','); // Assuming 'index' is a comma-separated string

      // Create the formatted 'index' and 'index_name'
      const formattedIndex = `(${indexValues.join(',')})`;
      const formattedIndexName = `${indexValues.join('_')}_unique`;

      return {
        ...item, // Keep other properties from the original object
        index: formattedIndex,
        index_name: formattedIndexName
      };
    });

    // Construct the request body
    const body = {
      id: this.editSelectionElement.id,
      query_index: queryIndexTransformed, // Use the transformed query index
      updated_by: this.api.user_id,
    };

    // Make the API call
    this.api.patchGmusData(url, body).subscribe(res => this.handleResponse(res, "updated"));
  }

/**
 * Handles the response from the server for both create and update operations.
 * @param res - The response object from the server.
 * @param action - The action performed, either "created" or "updated".
 */
private handleResponse(res: any, action: string) {
  if (res.status === 200) {
      this.goBack();
      this.editSelectionElement = null;
      this.notify.notify(`Template has been ${action} successfully`, "success");
  } else { // Handle unsuccessful response
      this.notify.notify(res.message, "warn");
  }
}
// Function to reset the form to "Add Mode"
resetForm() {
  this.csvTempFormGroup.reset(); // Reset the form group
  this.csvTempFormArray.clear(); // Clear the form array
  this.addRow(); // Add an initial empty row for a new template
  this.isEdit = false; // Set to Add mode
  this.button = "Submit"; // Update button label
  this.editSelectionElement = null; // Reset edit selection element
}
}
