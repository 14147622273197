export interface StatusOptions {
  status_value: string;
  label: string;
}

export const StatusOptions: StatusOptions[] = [
  {
    status_value: "1",
    label: "Running",
  },
  {
    status_value: "2",
    label: "Success",
  },
  {
    status_value: "-1",
    label: "Failed",
  },
  {
    status_value: "-2",
    label: "Stopped",
  },
  {
    status_value: "0",
    label: "Started",
  },
  {
    status_value: "4",
    label: "Synced",
  },
]
