// Define the external interface
export interface FilterOption {
  id: number;
  name: string;
  label: string;
  view: boolean;
}

// Example of using the interface with your arrays
export const road_filter: FilterOption[] = [
  { id: 1, name: "calculate_setback", label: "Calculate Setback", view: false },
  // { id: 5, name: "duplicate_id", label: "Suppress duplicate id overlap check", view: false },
];

export const parcel_filter: FilterOption[] = [
  { id: 1, name: "parcel_overlap", label: "Suppress parcel overlap check", view: false },
  { id: 2, name: "neigh_overlap", label: "Suppress Neighbourhood overlap check", view: false },
  { id: 3, name: "block_overlap", label: "Suppress block overlap check", view: false },
  { id: 4, name: "street_overlap", label: "Suppress street overlap check", view: false },
  // { id: 5, name: "duplicate_id", label: "Suppress duplicate id overlap check", view: false },
];

export const unit_layout_filters: FilterOption[] = [
  { id: 1, name: "block_check", label: "Suppress block check", view: false },
  // { id: 5, name: "duplicate_id", label: "Suppress duplicate id overlap check", view: false },
];
