import {
  Component,
  OnInit,
} from "@angular/core"; // Import Angular core functionalities
import { ApiserviceService } from "../../apiservice.service"; // Import custom API service for backend interactions
import { environment } from "../../../environments/environment"; // Import custom API service for backend interactions
import { NotificationService } from "../services/notification.service"; // Service for displaying notifications to the user
import { AdminMenusService } from "../admin-sidebar/admin-menus.service"; // Service for managing the active state of admin menu items
import { GisDataService } from "../gis-data-upload/gis-data-upload.service"; // Service related to GIS data handling
import { CommonfunctionService } from "../../../../src/app/services/commonfunction.service"; // Common function service for reusable utilities
import {initialSharedModel,SharedModel}from "../shared/sharedmodel/shared.model"; // model file for  resuse  common variables in difrrent component
// Component decorator defining the component's metadata
@Component({
  selector: "app-upload-momra-zone-code", // Component's HTML tag
  templateUrl: "./upload-momra-zone-code.component.html", // Template URL for the component's HTML
  styleUrls: ["./upload-momra-zone-code.component.scss"], // Stylesheet URL for the component's styles
})
export class UploadMomraZoneCodeComponent implements OnInit {
  sharedModel: SharedModel = { ...initialSharedModel }; // use common variable from share model file
  totalCount: number; // Total count of records
  selectedData: any[]; // Array to hold fetched data
  selectedColumns: string[]; // Array to define which columns to display in the table
  isManageUploaded: boolean = false;
  isManageTemplate: boolean = false;
  gmus_log_path = environment.gmusUrl + "csv-log?file_name=";
  momra_zone_code_access = {
    GET: false, // Permission for GET requests
    POST: false, // Permission for POST requests
    PATCH: false, // Permission for PATCH requests
    DELETE: false, // Permission for DELETE requests
  };
  // Array defining columns to be displayed in the table
  displayColumn = [
    "upload_id",
    "file_name",
    "added_by",
    "updated_by",
    "added_date",
    "updated_date",
    "log",
    "status",
    "action",
  ];
  // Configuration object for button actions and styles
  buttonConfig = {
    log: [
      {
        label: "Validate Log", // Label for the button
        action: (element: any) => this.downloadLog(element, "validate"), // Function to execute on click
        style: {
          // Styling for the button
          border: "1px solid #e0e8ff",
          color: "#0033cc",
          backgroundColor: "#e0e8ff",
          hover: {
            // Hover styles
            backgroundColor: "#c0d9ff",
          },
          disabled: {
            // Disabled state styles
            color: "gray",
            backgroundColor: "#f0f0f0",
          },
        },
        class: "log-button", // CSS class for additional styling additional styling
      },
      {
        label: "Accept Log",
        action: (element: any) => this.downloadLog(element, "accept"), // Function to execute on click
        style: {
          border: "1px solid #d0ffd0",
          color: "#007a00",
          backgroundColor: "#d0ffd0",
          hover: {
            backgroundColor: "#b0ffb0",
          },
          disabled: {
            color: "darkgray",
            backgroundColor: "#e0e0e0",
          },
        },
        class: "log-button",
      },
    ],
    action: [
      {
        label: "Accept",
        action: (element) => this.acceptOrRejectRequest(element, "accept"), // Function to accept request
        style: {
          border: "1px solid #24CB6B",
          color: "#ffffff",
          padding: "5px 10px",
          width: "100px",
          backgroundColor: "#24CB6B",
          hover: {
            backgroundColor: "#d9ffd9",
          },
        },
      },
      {
        label: "Reject",
        action: (element) => this.acceptOrRejectRequest(element, "reject"), // Function to reject request
        style: {
          border: "1px solid #FF8078",
          color: "#ffffff",
          padding: "5px 10px",
          width: "100px",
          backgroundColor: "#FF8078",
        },
      },
      {
        label: "Stop Process",
        action: (element) => this.stopProcess(element), // Function to stop-process request
        style: {
          border: "1px solid ##ABABAB",
          color: "#ffffff",
          padding: "5px 10px",
          width: "150px",
          backgroundColor: "#ABABAB",
          hover: {
            backgroundColor: "#ffe5cc",
          },
          disabled: {
            color: "darkorange",
            backgroundColor: "#f5f5f5",
          },
        },
      },
      {
        label: "download",
        action: (element) => this.downloadData(element), // Function to accept request
        style: {
          border: "1px solid #24CB6B",
          color: "#ffffff",
          padding: "5px 10px",
          width: "100px",
          backgroundColor: "#24CB6B",
          hover: {
            backgroundColor: "#d9ffd9",
          },
        },
      },
    ],
  };
  tempList: any;
  // Constructor for dependency injection
  constructor(
    private api: ApiserviceService, // Injecting API service for backend communication
    private notify: NotificationService, // Injecting notification service for user notification
    private active: GisDataService, // Injecting GIS data service
    private menus: AdminMenusService, // Injecting admin menus service
    private fnc: CommonfunctionService // Injecting common function service
  ) {
    this.menus.setActive("csv_upload"); // Setting the active menu item in admin sidebar
    this.active.setActive(""); // Resetting active state in GIS data service
  }
  // Lifecycle hook that runs after component initialization
  ngOnInit(): void {
    // Set access permissions based on resource
    this.getAllowResourcesApi();
  }
   // getting allow resources
   getAllowResourcesApi() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
    this.api.getUmsData(body).subscribe((res: any) => {
      this.api.allowResources = res.data;
      this.getAllowResource();
      this.selectedColumns = this.displayColumn; // Initialize selected columns for display
      this.getUploadedData(); // Fetch initial data for the table
      this.getTempList();
    });
  }

  getAllowResource() {
    this.momra_zone_code_access =
      this.fnc.checkResourceAccess("csv_data_upload");
  }
  // Method to fetch uploaded data with pagination and sorting
  getUploadedData(): void {
    // Check if user has GET access permission
    if (!this.momra_zone_code_access.GET) {
      this.notify.notify(
        "Access declined. You are not authorized to get data.",
        "warn"
      );
      return; // Exit function
    }
    // Construct URL with query parameters for pagination and sorting
    let url = `upload-csv?sort_asc=true&sort_by=file_name&limit=${this.sharedModel.limit}&offset=${this.sharedModel.offset}`;
    if (this.sharedModel.offset == 0) url += `&is_count=true`; // Add total count parameter for initial load
    this.sharedModel.loader = true; // Show loader while fetching data
    this.api.getGmusData(url).subscribe({
      next: (res: any) => {
        // Handle successful response
        this.sharedModel.loader = false; // Hide loader
        if (res && res.status === 200) {
          this.selectedData = res.data; // Assign fetched data to selectedData
          if (this.sharedModel.offset == 0) {
            this.totalCount = res.totalRecord; // Set total count on first load
            // this.sync();
          }
        } else {
          this.selectedData = []; // Handle case with no data
        }
      },
      error: (_err) => {
        // Handle errors from API call
        this.selectedData = []; // Clear data on error
        this.sharedModel.loader = false; // Hide loader
      },
    });
  }
  // Method to get CSS class and color based on status code
  getStatusClass(status: any): { class: string; color: string } {
    // Mapping of status codes to classes and colors
    const StatusClassMap = {
      "-1": { class: "Invalid", color: "red" },
      "1": { class: "Valid", color: "blue" },
      "2": { class: "Accepted", color: "green" },
      "-2": { class: "Accept-invalid", color: "orange" },
      "0": { class: "Uploaded", color: "purple" },
      "-3": { class: "Rejected", color: "darkred" },
      "4": { class: "Accept-progress", color: "yellow" },
      "3": { class: "Validating", color: "gray" },
    };
    return (
      StatusClassMap[status] || { class: "status-default", color: "black" }
    );
  }
  // Method to download logs based on selected row and action type
  downloadLog(row: any, action: string): void {
    // Check if the code is running in the browser
    // if (isPlatformBrowser(this.platformId)) {
    // Construct URL for log download based on action type
    const url =
      action === "validate"
        ? `csv-log?file_name=${row.validate_url}&city_code=${this.api.city_code}` // URL for validate log
        : `csv-log?file_name=${row.accept_url}&city_code=${this.api.city_code}`; // URL for accept log
    window.open(`${environment.gmusUrl}${url}`, "_blank"); // Open the log URL in a new browser tab
  }
  // Method to accept or reject a request based on action type
  acceptOrRejectRequest(row: any, action: any): void {
    // Check if the user has PATCH access permission
    if (!this.momra_zone_code_access.PATCH) {
      this.notify.notify(
        "Access declined: You are not authorized to update data.",
        "warn"
      ); // Show warning if no permission
      return; // Exit function
    }
    // Create request body with necessary details for accept/reject action
    const body = {
      type: action, // Action type (accept/reject)
      upload_id: row.upload_id, // Upload ID from the selected row
      user_id: this.api.user_id, // User ID from the API service
      file_url:row.file_url
    };
    // API call to patch data with the accept/reject action
    this.api.patchGmusData("upload-csv/status", body).subscribe({
      next: (res: any) => {
        // Handle successful response
        this.notify.notify(res.message, "success"); // Show success notification
        this.getUploadedData(); // Refresh data in the table
      },
      error: (err) => {
        // Handle errors from API call
        this.notify.notify(err.error.message, "error"); // Show error notification
      },
    });
  }
  // Method to stop a process, currently for logging purposes
  stopProcess(row: any) {
    let url = `stop-csv-upload?upload_id=${row.upload_id}&process_name=gis_upload_csv&user_id=${this.api.user_id}`;
    this.sharedModel.loader = true;
    this.api.getGmusData(url).subscribe({
      next: (res: any) => {
        // Handle successful response
        if (res && res.status === 200) {
          this.notify.notify(res.message, "success"); // Show success notification
          this.sharedModel.loader = false;
          this.getUploadedData(); // Refresh data in the table
        }
      },
      error: (err) => {
        this.notify.notify(err.error.message, "error");
        this.sharedModel.loader = false;
        this.getUploadedData(); // Refresh data in the table
      },
    });
  }
  sync() {
    this.getUploadedData();
    this.getTempList();
  }
  // Method to handle pagination changes
  onPageChange(event: any) {
    this.sharedModel.limit = event.limit; // Update limit based on pagination event
    this.sharedModel.offset = event.offset; // Update offset based on pagination event
    this.getUploadedData(); // Fetch data for the new page
  }
  changePage(type: string) {
    if (!this.momra_zone_code_access.PATCH) {
      this.notify.notify(
        "Access declined: You are not authorized to update data.",
        "warn"
      ); // Show warning if no permission
      return; // Exit function
    }
    const pages = {
      'manage-uploaded-csv': { isManageUploaded: true, isManageTemplate: false },
      'manage-template': { isManageUploaded: false, isManageTemplate: true },
      'default': { isManageUploaded: false, isManageTemplate: false }
    };
    const selectedPage = pages[type] || pages['default'];
    this.isManageUploaded = selectedPage.isManageUploaded;
    this.isManageTemplate = selectedPage.isManageTemplate;
  }
  getTempList(){
    let url = `csv-master?sort_asc=true&sort_by=file_type`
    this.api
      .getGmusData(url)
      .subscribe({
        next: (res: any) => {
          this.tempList = res.data;
        },
        error: (err) => {
          console.error("Error fetching tables: ", err);
          this.tempList = [];
        },
      });
  }

  downloadCSV(tableName: string, data: any[]): void {
    // Step 1: Check if data is defined and is an array
    if (!data || !Array.isArray(data)) {
      console.error('Data is undefined or not an array:', data);
      return;
    }

    // If user entered "all", download all tables
    if (tableName.toLowerCase() === 'all') {
      data.forEach(item => {
        const columnNames = item.column_name.map(col => col.col_name);
        const csvContent = this.generateCSV(columnNames);
        this.triggerCSVDownload(csvContent, item.table_name);
      });
    } else {
      // Find the matching table based on table_name
      const matchingTable = data.find(item => item.file_type === tableName);

      if (matchingTable) {
        const columnNames = matchingTable.column_name.map(col => col.col_name);
        const csvContent = this.generateCSV(columnNames);
        this.triggerCSVDownload(csvContent, tableName);
      } else {
        console.error('Table name not found:', tableName);
      }
    }
  }

  generateCSV(columnNames: string[]): string {
    // Add headers (adjust as needed)
    let csvContent = columnNames.join(',') + '\n';

    // Add rows if necessary (currently no data, just headers)
    return csvContent;
  }

  triggerCSVDownload(csvContent: string, tableName: string): void {
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    const timestamp = Date.now();
    link.download = `${tableName}${"_"+timestamp}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  downloadData(element) {
console.log(element);
    let url = `${this.gmus_log_path}${element.file_url}&city_code=${this.api.city_code.toLowerCase()}`
    console.log(url);

    window.open(url, "_blank");
  }

}
