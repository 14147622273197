<div class="input-wrapper">
  <mat-form-field appearance="outline" floatLabel="always" class="custom-outline-border">
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      [type]="type"
      [value]="value"
      [placeholder]="placeholder"
      (input)="updateValue($event.target.value)"
      [required]="required"
    />
    <!-- Error for required field -->
    <mat-error *ngIf="required && !value">{{ errorMessage }}</mat-error>

    <!-- Error for invalid input -->
    <mat-error *ngIf="invalidInput">Please enter only comma-separated values.</mat-error>
  </mat-form-field>
</div>
