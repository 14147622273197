<div class="form-wrapper">
  <div class="year-drop-down">
    <mat-form-field appearance="outline" floatLabel="always">
      <input
        matInput
        [matDatepicker]="dp"
        readonly
        (click)="dp.open()"
        placeholder="Select Year"
        min="{{ startYear }}"
        max="{{ currentYear }}"
        [formControl]="date"
      />
      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker
        #dp
        startView="multi-year"
        (yearSelected)="chosenYearHandler($event, dp)"
        panelClass="example-month-picker"
      >
      </mat-datepicker>
    </mat-form-field>
  </div>

</div>
