<form [formGroup]="geoDataDownloadFormGroup">
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="col-6 heading">
        <h5>Geo Data Download</h5>
      </div>
      <app-year-picker
        [startYear]="startYear"
        [currentYear]="currentYear"
        (yearSelected)="handleYearSelected($event)"
      >
      </app-year-picker>
    </div>
    <div class="d-flex justify-content-start align-items-center gap-20 flex-wrap">
      <div>
        <app-gis-select
          [label]="'Select Layer'"
          [options]="options"
          formControlName="layer_his_control"
          (selectionChangecustom)="onSelectionChange($event)"
        >
        </app-gis-select>
      </div>
      <div>
        <app-gis-select
          [label]="'Select columns'"
          [options]="columnsOption"
          [multiple]="multiple"
          formControlName="column_control"  
        >
        </app-gis-select>
      </div>
      <div>
        <app-gis-custom-input [label]="'Remark'" formControlName="remark">
        </app-gis-custom-input>
      </div>
      <div *ngIf="geoDataDownloadFormGroup.value.layer_his_control == 'projects_his_with_uids'">
        <app-gis-custom-input [label]="'UIDs'" [commaSeparatedOnly]="isCommaSeparated" formControlName="uids">
        </app-gis-custom-input>
      </div>
      <div>
        <app-gis-primary-button
          [button_type]="'submit'"
          (onAction)="submit()"
          [isDisabled]="false"
          [button_label]="'Apply'"
        >
        </app-gis-primary-button>
      </div>
      <div>
        <div class="loder-btn">
          <button
            mat-mini-fab
            (click)="sync()"
            matTooltip="Refresh Table Data"
            class="refresh-btn"
          >
            <mat-icon>sync</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <!-- Data table component -->
    <app-gis-data-table
      (pageChange)="onPageChange($event)"
      [data]="selectedData"
      [columns]="displayColumns"
      [buttons]="buttonConfig"
      [totalCount]="totalCount"
      [getStatusClass]="getStatusClass"
    >
    </app-gis-data-table>
  </div>
</form>
  <!-- Loading indicator -->
  <ngx-loading
    [show]="sharedModel.loader"
    [config]="{ backdropBackgroundColour: 'rgba(255,255,255,0.3)' }"
  ></ngx-loading>
