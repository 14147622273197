<div class="table-wrapper">
  <form [formGroup]="formLayerGroup">
  <ng-container formArrayName="formLayerArray">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Serial Number Column -->
    <ng-container matColumnDef="sno">
      <th mat-header-cell *matHeaderCellDef>S.No</th>
      <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
    </ng-container>
    <!-- Column Name -->
    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">
        <!-- <div class="control-customization">
          <app-gis-custom-input
           formControlName="name"
            label="Name"
            placeholder="Entee Name"
            required="true"
            errorMessage="Name is required"
          >
          </app-gis-custom-input>
        </div> -->
        <input type="text" formControlName="name">
      </td>
    </ng-container>
    <!-- Column Name -->
    <ng-container matColumnDef="columnName">
      <th mat-header-cell *matHeaderCellDef>Column Name</th>
      <td mat-cell *matCellDef="let element">
        <div class="control-customization">
          <app-gis-custom-input
            label="Column Name"
            placeholder="Enter Column Name"
            required="true"
            errorMessage="Column Name is required"
          >
          </app-gis-custom-input>
        </div>
      </td>
    </ng-container>

    <!-- Data Type -->
    <ng-container matColumnDef="dataType">
      <th mat-header-cell *matHeaderCellDef>Data Type</th>
      <td mat-cell *matCellDef="let element">
        <div class="control-customization">
          <app-gis-select [label]="'Select data type'" [options]="options">
          </app-gis-select>
        </div>
      </td>
    </ng-container>

    <!-- JSON Key -->
    <ng-container matColumnDef="jsonKey">
      <th mat-header-cell *matHeaderCellDef>JSON Key</th>
      <td mat-cell *matCellDef="let element">
        <div class="control-customization">
          <app-gis-custom-input
            label="JSON Key"
            placeholder="Enter JSON Key"
            required="true"
            errorMessage="JSON Key is required"
          >
          </app-gis-custom-input>
        </div>
      </td>
    </ng-container>

    <!-- Reference Table -->
    <ng-container matColumnDef="refTable">
      <th mat-header-cell *matHeaderCellDef>Ref. Table</th>
      <td mat-cell *matCellDef="let element">
        <div class="control-customization">
          <app-gis-custom-input
            label="Ref. Table"
            placeholder="Enter Ref. Table"
            required="true"
            errorMessage="Ref. Table is required"
          >
          </app-gis-custom-input>
        </div>
      </td>
    </ng-container>

    <!-- Reference Column Name -->
    <ng-container matColumnDef="refColName">
      <th mat-header-cell *matHeaderCellDef>Ref. Col. Name</th>
      <td mat-cell *matCellDef="let element">
        <div class="control-customization">
          <app-gis-custom-input
            label="Ref. Col. Name "
            placeholder="Enter Ref. Col. Name "
            required="true"
            errorMessage="Ref. Col. Name  is required"
          >
          </app-gis-custom-input>
        </div>
      </td>
    </ng-container>

    <!-- Reference Condition Name -->
    <ng-container matColumnDef="refConditionName">
      <th mat-header-cell *matHeaderCellDef>Ref. Condition Name</th>
      <td mat-cell *matCellDef="let element">
        <div class="control-customization">
          <app-gis-custom-input
            label="Ref. Condition Name"
            placeholder="Enter Ref. Condition Name"
            required="true"
            errorMessage="Ref. Condition Name is required"
          >
          </app-gis-custom-input>
        </div>
      </td>
    </ng-container>

    <!-- Required Column -->
    <ng-container matColumnDef="required">
      <th mat-header-cell *matHeaderCellDef>Required</th>
      <td mat-cell *matCellDef="let element">
        <div class="control-customization">
          <app-gis-custom-input
            label="Required"
            placeholder="Enter Required"
            required="true"
            errorMessage="Field is required"
          >
          </app-gis-custom-input>
        </div>
      </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <button mat-button (click)="removeRow(i)"><mat-icon>close</mat-icon></button>
        <button mat-button (click)="addRow()"><mat-icon>add</mat-icon></button>
      </td>
    </ng-container>

    <!-- Header Row -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <!-- Data Rows -->
  <ng-container *ngFor="let layerItem of layerFormsArray.controls; let i = index" [formGroupName]="i">
    <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  </ng-container>
  </table>
</ng-container>
</form>
<button type="button" (click)="submit()">click</button>
</div>
