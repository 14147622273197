import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AdminComponent } from '../admin.component';
import { AuthGuard } from '../services/auth.guard';
import { NotifyDetailComponent } from '../notification/notification.component';
import { GisDataUploadComponent } from '../gis-data-upload/gis-data-upload.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { LegacyDataUploadComponent } from '../legacy-data-upload/legacy-data-upload.component';
import { PoiComponent } from '../poi/poi.component';
import { ImageUploadComponent } from '../image-upload/image-upload.component';
import { GisAttributesComponent } from "../gis-attributes/gis-attributes.component";
import { CalculateSyncComponent } from "../calculate-sync/calculate-sync.component";
import { DbProcessesComponent } from '../db-processes/db-processes.component';
import { MarksUploadComponent } from '../marks-upload/marks-upload.component';
import { GisSyncComponent } from '../gis-sync/gis-sync.component';
import { LogSettingComponent } from '../log-setting/log-setting.component';
import { SettingsComponent } from '../settings/settings.component';
import { SicResultsComponent } from '../sic-results/sic-results.component';
import { SicResultsDetailsComponent } from '../sic-results/sic-results-details/sic-results-details.component';
import { CalculateUploadComponent } from '../calculate-upload/calculate-upload.component';
import { SparkCalculateSyncComponent } from '../spark-calculate-sync/spark-calculate-sync.component';
import { UploadMomraZoneCodeComponent } from '../upload-momra-zone-code/upload-momra-zone-code.component';
import { GeoDataDownloadComponent } from '../geo-data-download/geo-data-download.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'admin', component: AdminComponent, canActivate: [AuthGuard],
        children: [
          { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
          { path: 'notifications', component: NotifyDetailComponent, canActivate: [AuthGuard] },
          { path: 'uploadgis', component: GisDataUploadComponent, canActivate: [AuthGuard] },
          { path: '', component: GisDataUploadComponent, canActivate: [AuthGuard] },
          { path: 'uploadlegacy', component: LegacyDataUploadComponent, canActivate: [AuthGuard] },
          { path: 'poi', component: PoiComponent, canActivate: [AuthGuard] },
          { path: 'image_video', component: ImageUploadComponent, canActivate: [AuthGuard] },
          { path: 'gis-attributes', component: GisAttributesComponent, canActivate: [AuthGuard] },
          { path: 'calculate-sync', component: CalculateSyncComponent, canActivate: [AuthGuard] },
          { path: 'calculate-upload', component: CalculateUploadComponent, canActivate: [AuthGuard] },
          { path: 'dbprocess', component: DbProcessesComponent, canActivate: [AuthGuard] },
          { path: 'marksupload', component: MarksUploadComponent, canActivate: [AuthGuard] },
          { path: 'gis-sync', component: GisSyncComponent, canActivate: [AuthGuard] },
          { path: 'log-settings', component: LogSettingComponent, canActivate: [AuthGuard] },
          { path: 'sic-results', component: SicResultsComponent, canActivate: [AuthGuard] },
          { path: 'sic-results/:option_id/:query_id', component: SicResultsDetailsComponent, canActivate: [AuthGuard] },
          { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
          { path: 'spark-calculate-sync', component: SparkCalculateSyncComponent, canActivate: [AuthGuard] },
          { path: 'geo-data-download', component: GeoDataDownloadComponent, canActivate: [AuthGuard] },
          { path: 'csv-data-upload', component: UploadMomraZoneCodeComponent, canActivate: [AuthGuard] },
        ]
      },
    ])
  ],
  declarations: [],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
