<div class="parent-container" [ngStyle]="{'height':height+'px'}" *ngIf="this.upload_access.GET">
    <div appearance="outlined" class="example-card">
      <form [formGroup]="fileUploadForm" (ngSubmit)="uploadFile(fileUploadForm)">
      <div class="d-flex justify-content-between align-items-center flex-wrap">
          <!-- <mat-card-header> -->

           <div class="d-flex justify-content-between align-items-center gap-20 flex-wrap">
            <mat-card-title class="title">GIS Data Upload</mat-card-title>

           </div>
            <!-- <mat-select class="year-dropdown" [(ngModel)]="selectedYear" placeholder="Year" (selectionChange)="setGlobleYear()">
                <input class="searchIn" mat-input [(ngModel)]="search_year" [ngModelOptions]="{standalone: true}" placeholder="Search..." (keydown)="$event.stopPropagation();" />
                <mat-option *ngFor="let year of yearList | searchfilter:'val':search_year" [value]="year.val">
                    {{year.val}}</mat-option>
            </mat-select> -->
       <div class="d-flex justify-content-between align-items-center gap-20 flex-wrap">
        <div class="filter" *ngIf="filter">
          <!-- <span>Filter By Status:</span> -->
          <mat-select class="filter-by" placeholder="Select Status" (selectionChange)="filterByStatus($event.value)">
              <!-- <mat-option value=''>Select Filter</mat-option> -->
              <mat-option value="All">All</mat-option>
              <mat-option *ngFor="let value of status" [value]="value.status_code">
                  {{value.value}}
              </mat-option>
          </mat-select>
      </div>
        <app-year-picker
        [startYear]="startYear"
        [currentYear]="currentYear"
        (yearSelected)="handleYearSelected($event)"
      >
      </app-year-picker>
       </div>
        <!-- </mat-card-header> -->
      </div>
      <div class="d-flex justify-content-start align-items-center gap-20 flex-wrap">
        <div class="w-100-767">
          <app-gis-select
            [label]="'Select Layer'"
            [options]="layerListOption"
            formControlName="layer"
            (selectionChangecustom)="initValidators($event)"
          >
          </app-gis-select>
        </div>
        <div *ngIf="layer == 'parcels' || layer == 'units'" class="w-100-767">
          <mat-form-field appearance="outline" floatLabel="always" class="w-100-767">
            <mat-label>Select Neighbourhood</mat-label>
              <mat-select placeholder="Select Neighbourhood" formControlName="selectedneightUid" (openedChange)="searchNeigh=''">
                  <input class="searchIn" mat-input [(ngModel)]="searchNeigh" [ngModelOptions]="{standalone: true}" placeholder="Type Name here to search..." (keydown)="$event.stopPropagation();" />
                  <mat-option *ngFor="let neightbour of neighbourhoods | neighFilter:searchNeigh" [value]="neightbour.neigh_uid">
                      {{neightbour.neigh_name}}</mat-option>
              </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="layer == 'units'" class="w-100-767">
          <mat-form-field  appearance="outline" floatLabel="always" class="w-100-767">
            <mat-select placeholder="Select Block" formControlName="selectedBlock" (openedChange)="searchBlock = ''">
                <input class="searchIn" mat-input [(ngModel)]="searchBlock" [ngModelOptions]="{standalone: true}" placeholder="Type Name here to search..." (keydown)="$event.stopPropagation();" />
                <mat-option *ngFor="let block of blocks | searchfilter:'block_name':searchBlock " [value]="block.block_uid">{{block.block_name}}</mat-option>
            </mat-select>
        </mat-form-field>
        </div>
        <div *ngIf="layer == 'units'" class="w-100-767">
          <mat-form-field  appearance="outline" floatLabel="always" class="w-100-767">
            <mat-select placeholder="Select Building" formControlName="selectedBuilding" (openedChange)="searchBuilding = ''">
                <input class="searchIn" mat-input [(ngModel)]="searchBuilding" [ngModelOptions]="{standalone: true}" placeholder="Type UID or name here to search..." (keydown)="$event.stopPropagation();" />
                <mat-option *ngFor="let building of buildings | searchfilter:'searchparam':searchBuilding" [value]="building.building_uid">{{building.building_uid}} - {{building.building_name}}</mat-option>
            </mat-select>
        </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center flex-wrap" class="d-flex justify-content-start align-items-center gap-20 flex-wrap w-100-767">
          <div class="w-100-767">
              <div>
                  <span class="fntcustom" *ngIf="fileName">{{fileName}}</span>
              </div>
              <div class="btnGrid w-100-767">
                  <div class="upload-btn-wrapper w-100-767">
                      <button mat-button class="browesbtn w-100-767">
                          <mat-icon class="upicon" svgIcon="cloud_upload"></mat-icon> Browse
                      </button>
                      <input style="z-index: 9;" type="file" name="myfile" #myFileInput (change)="handleFileInput($event)" formControlName="file" accept=".zip" />
                  </div>
                  <button  class="w-100-767" type="submit" mat-raised-button color="primary" [disabled]="fileUploadForm.invalid || loading">
                  <span *ngIf="!loading">Upload</span>
                  <span *ngIf="loading">Uploading...</span>
                  </button>
              </div>
          </div>
          <div class="loder-btn">
            <button
              mat-mini-fab
              (click)="getUploadedFilesList(fileUploadForm.value.layer, false)"
              matTooltip="Sync"
              class="refresh-btn"
            >
              <mat-icon>sync</mat-icon>
            </button>
          </div>
          <div *ngIf="layer == 'blocks'">
              <button mat-raised-button color="primary" (click)="execute_blocks()" matTooltip="Re-Calculate block to block distance">Recalculate block distance</button>
          </div>
      </div>
      <div *ngIf="layer == 'road'" fxLayout="row" fxLayoutAlign="space-between center" class="d-flex justify-content-start align-items-center gap-20 flex-wrap">
        <div class="w-100-767 d-flex justify-content-start align-items-center gap-20 flex-wrap">
            <mat-form-field appearance="outline" floatLabel="always" class="w-100-767">
                <mat-select placeholder="Select Neighbourhood" formControlName="selectedneightUid" (selectionChange)="getBlocks($event.value)" (openedChange)="searchNeigh = ''">
                    <input class="searchIn" mat-input [(ngModel)]="searchNeigh" [ngModelOptions]="{standalone: true}" placeholder="Type Name here to search..." (keydown)="$event.stopPropagation();" />
                    <mat-option *ngFor="let neightbour of neighbourhoods | neighFilter:searchNeigh" [value]="neightbour.neigh_uid">
                        {{neightbour.neigh_name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-100-767" *ngIf="selectedneightUid!=null"  appearance="outline" floatLabel="always">
                <mat-select placeholder="Select Blocks" (selectionChange)="selectblock($event)" formControlName="selectedBlock" (openedChange)="searchBlock = ''">
                    <input class="searchIn" mat-input [(ngModel)]="searchBlock" [ngModelOptions]="{standalone: true}" placeholder="Type Name here to search..." (keydown)="$event.stopPropagation();" />
                    <mat-option [value]="null">Select Block</mat-option>
                    <mat-option *ngFor="let blocks of blocks | searchfilter:'block_name':searchBlock" [value]="blocks.block_uid">
                        {{blocks.block_name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-100-767"  appearance="outline" floatLabel="always">
                <input matInput type="text" [(ngModel)]="parcelEntered" placeholder="Enter Parcels" formControlName="parcels">
            </mat-form-field>
        </div>
        <div class="w-100-767 d-flex justify-content-start align-items-center" >
            <a mat-raised-button color="primary"   class="w-5-767 " (click)="openConfirmation()" matTooltip="Setback calculation" style="color: white;">
           Calculate Setback </a>
            <a class="w-5-767 ml-3" mat-raised-button color="primary" (click)="downloadLog()" matTooltip="Download log" style="color: white;">Download log</a>
        </div>
    </div>
      </div>
        <!-- <mat-card-content> -->
            <ngx-loading [show]="loader1" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
            <div class="gis_data_form">

                    <!-- <div class="bottom-border">
                        <mat-radio-group formControlName="layer" (change)="initValidators($event)">
                            <mat-radio-button class="radio_text" [checked]="check(option)" *ngFor="let option of options" [value]="option.value">{{option.label}}</mat-radio-button>
                        </mat-radio-group>
                    </div> -->
                    <div class="d-flex justify-content-start align-items-center gap-20 flex-wrap">
                      <div>

                      </div>
                      <!-- <div> -->


                      <!-- </div> -->
                      </div>

                    <!-- <div *ngIf="layer == 'parcels' || layer == 'units'">
                        <mat-form-field>
                            <mat-select placeholder="Select Neighbourhood" formControlName="selectedneightUid" (openedChange)="searchNeigh=''">
                                <input class="searchIn" mat-input [(ngModel)]="searchNeigh" [ngModelOptions]="{standalone: true}" placeholder="Type Name here to search..." (keydown)="$event.stopPropagation();" />
                                <mat-option *ngFor="let neightbour of neighbourhoods | neighFilter:searchNeigh" [value]="neightbour.neigh_uid">
                                    {{neightbour.neigh_name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field *ngIf="layer == 'units'" class="ml-1">
                            <mat-select placeholder="Select Block" formControlName="selectedBlock" (openedChange)="searchBlock = ''">
                                <input class="searchIn" mat-input [(ngModel)]="searchBlock" [ngModelOptions]="{standalone: true}" placeholder="Type Name here to search..." (keydown)="$event.stopPropagation();" />
                                <mat-option *ngFor="let block of blocks | searchfilter:'block_name':searchBlock " [value]="block.block_uid">{{block.block_name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field *ngIf="layer == 'units'" class="ml-1">
                            <mat-select placeholder="Select Building" formControlName="selectedBuilding" (openedChange)="searchBuilding = ''">
                                <input class="searchIn" mat-input [(ngModel)]="searchBuilding" [ngModelOptions]="{standalone: true}" placeholder="Type UID or name here to search..." (keydown)="$event.stopPropagation();" />
                                <mat-option *ngFor="let building of buildings | searchfilter:'searchparam':searchBuilding" [value]="building.building_uid">{{building.building_uid}} - {{building.building_name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> -->
                    <div *ngIf="layer == 'parcels'">
                        <h6>Suppress Validations</h6>
                        <mat-checkbox class="filterlist" *ngFor="let f of parcel_filter" [checked]="checkAllSelected(f)" (change)="updatefilter($event, f)">{{f.label}}</mat-checkbox>
                    </div>
                    <div *ngIf="layer == 'road'">
                        <mat-checkbox class="filterlist" *ngFor="let f of road_filter" [checked]="checkAllSelected(f)" (change)="updatefilter($event, f)">{{f.label}}</mat-checkbox>
                    </div>

                    <div *ngIf="layer == 'units'">
                        <h6>Suppress Validations</h6>
                        <mat-checkbox class="filterlist" *ngFor="let f of unit_layout_filter" [checked]="checkAllSelected(f)" (change)="updatefilter($event, f)">{{f.label}}</mat-checkbox>
                    </div>

            </div>
          </form>
        <!-- </mat-card-content> -->
        <div class="bbar w-100" *ngIf="loading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </div>
    <!--  -->
  <!-- Data table component -->
<div class="table-container">
  <app-gis-data-table
  (pageChange)="onPageChange($event)"
  [data]="gisDataReport"
  [columns]="selectedColumns"
  [totalCount]="total"
  [getStatusClass]="getStatusClass"
  [buttons]="buttonConfig"
>
</app-gis-data-table>
</div>

    <!-- <mat-card appearance="outlined" class="example-card">
        <mat-card-header>
            <mat-card-title class="title">GIS Data Report</mat-card-title>
        </mat-card-header>
        <mat-card-content class="tabs">
            <div class="bottom-borderd">
                <div>
                    <button class="margr5" *ngFor="let option of options" [ngClass]="{'active': getActive_(option)}" (click)="getUploadedFilesList(option.value, true)">
            {{option.label}}</button>
                </div>
            </div>
            <div class="table-estater table">
                <ng-container *ngIf="selectedlayer && gisDataReport.length > 0">
                    <table class="attr-table table_data">
                        <thead>
                            <tr>
                                <th> S. No. </th>
                                <th> Upload Id </th>
                                <th> Name </th>
                                <th> Added Date </th>
                                <th> Accept Date </th>
                                <th> Accept Time </th>
                                <th> Validate time </th>
                                <th> Existing Count </th>
                                <th> Add Count </th>
                                <th *ngIf="unitLayout || isLayerAllowed(selectedlayer)">Conflict</th>
                                <th> Delete Count</th>
                                <th> Update Count</th>
                                <th *ngIf="selectedlayer == 'parcels' || selectedlayer == 'units'"> Suppress Validations</th>
                                <th> Check</th>
                                <th> User Name </th>
                                <th> Status </th>
                                <th> View Log </th>
                                <th> Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let gisdata of gisDataReport;">
                                <tr>
                                    <td width="5%"> {{gisdata.sn}}</td>
                                    <td> {{gisdata.upload_id}}</td>
                                    <td> {{gisdata.name}} </td>
                                    <td> {{gisdata.added_date}} </td>
                                    <td> {{gisdata.accept_date}} </td>
                                    <td> {{gisdata.accept_time}} </td>
                                    <td> {{gisdata.validate_time}} </td>
                                    <td>{{gisdata.existing_count}}</td>
                                    <td>{{gisdata.add_count}}</td>
                                    <td *ngIf="!unitLayout && isLayerAllowed(selectedlayer)">
                                        <button mat-raised-button (click)="openConflictDialog(gisdata)" color="primary" [disabled]="gisdata.conflict == null">Show data</button>
                                    </td>
                                    <td *ngIf="unitLayout">
                                        <span *ngIf="gisdata.conflict == null && gisdata.uid == null">No Conflicts</span>
                                        <p *ngIf="gisdata.conflict == null && gisdata.uid" title="{{gisdata.uid}}" class="ellipsis">{{gisdata.uid}}</p>
                                        <div *ngIf="gisdata.conflict != null" class="conflicts">
                                            <mat-select [value]="getCurrentBuild(gisdata)" class="filter-by" [disabled]="gisdata.status == 'Rejected'" (selectionChange)="setBuilding($event, gisdata.upload_id)" placeholder="Select Building Uid">
                                                <mat-option value="create_new" class="bg_blue">Create New Building</mat-option>
                                                <mat-option *ngFor="let buildUid of gisdata.conflict" [value]="buildUid.val">
                                                    <div class="conflicts">
                                                        <span style="width: 85px;">{{buildUid.val}}</span><span *ngIf="buildUid.key">|</span><span *ngIf="buildUid.key">{{buildUid.key}}</span>
                                                    </div>
                                                </mat-option>
                                            </mat-select>
                                            <button mat-button class="update blue" *ngIf="selectedBuildUid && (gisdata.upload_id == selectedUploadId)" (click)="update(gisdata)">
                                                <mat-icon class="save">save</mat-icon>
                                            </button>
                                            <button mat-button class="update" *ngIf="selectedBuildUid && (gisdata.upload_id == selectedUploadId)" (click)="unSelect()">
                                                <mat-icon class="close">close</mat-icon>
                                            </button>
                                        </div>

                                    </td>
                                    <td>{{gisdata.delete_count}}</td>
                                    <td>{{gisdata.update_count}}</td>
                                    <td *ngIf="selectedlayer == 'parcels' || selectedlayer == 'units'">{{gisdata?.suppress_validations}}</td>
                                    <td [ngClass]="{'red': gisdata.check != 0}">
                                        {{gisdata.check}}</td>
                                    <td>{{gisdata.user_name}}</td>
                                    <td *ngIf="gisdata.status=='Accepted'" class="text-center" [ngStyle]="gisdata.status=='Accepted' ? {'background': '#40a91e','color':'#fff'} : {}">
                                        {{gisdata.status}}</td>
                                    <td *ngIf="gisdata.status=='Invalid'" class="text-center" [ngStyle]="gisdata.status=='Invalid' ? {'background': 'red','color':'#fff'} : {}">{{gisdata.status}}
                                    </td>
                                    <td *ngIf="gisdata.status!='Invalid' && gisdata.status!='Accepted'" class="text-center">{{gisdata.status}}</td>
                                    <td>
                                        <a target="_blank" href="{{gisdata.errfile_link}}" class="bordered line-hith" mat-button>Validate Log</a>
                                        <a [disabled]="!isacceptView(gisdata.status)" target="_blank" href="{{gisdata.accept_errfile_link}}" class="bordered line-hith ml-1" mat-button>Accept Log </a>
                                    </td>
                                    <td>
                                        <button [disabled]="!isAccepted(gisdata.status) || checkDis(gisdata.upload_id)" [ngClass]="{btndisabled: !isAccepted(gisdata.status)}" class="bordered line-hith prim mr-1" mat-button (click)="action(gisdata, 'accept')">Accept</button>
                                        <button [disabled]="!isRejected(gisdata.status)" [ngClass]="{btndisabled: !isRejected(gisdata.status)}" class="bordered line-hith warn mr-1" mat-button (click)="action(gisdata, 'reject')">Reject</button>
                                        <button [disabled]="!showStopbutton(gisdata.status)" [ngClass]="{btndisabled: !showStopbutton(gisdata.status)}" class="bordered line-hith warn" mat-button (click)="stopprocess(gisdata,'parcels')">Stop Process</button>
                                    </td>
                                </tr>

                            </ng-container>
                        </tbody>
                    </table>
                    <div *ngIf="loader" class='loader-div'>
                        <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
                    </div>
                    <div *ngIf="acceptLoader" class='loader-div'>
                        <ngx-loading [show]="acceptLoader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
                    </div>
                </ng-container>
            </div>
            <mat-paginator [length]="total" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageAction($event)">
            </mat-paginator>
        </mat-card-content>
    </mat-card> -->
</div>
                                <!--  -->
                                <!-- <ng-container *ngIf="gisdata.other_status && gisdata.other_status.length">
                  <ng-container *ngFor="let status of gisdata.other_status;let i=index;">
                    <tr class="otherStatus">
                      <td>
                        {{gisdata.sn}}.{{i+1}}
                      </td>
                      <td> {{gisdata.upload_id}}</td>
                      <td> {{status.name}} </td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>{{gisdata.user_name}}</td>
                      <td>{{getSubProcessStatus(status.status)}}</td>
                      <td>
                        <a [disabled]="status.status=='0'" (click)="downloadLogSub(gisdata.upload_id)"
                          class="bordered line-hith ml-1" mat-button>Download
                          Log
                        </a>
                      </td>
                      <td>
                        <button [disabled]="status.status=='1' || status.status=='4' || gisdata.status!='Accepted'"
                          [ngClass]="{btndisabled: status.status=='1' || status.status=='4' || gisdata.status!='Accepted'}"
                          class="bordered line-hith prim mr-1" mat-button
                          (click)="calculateDirection(gisdata)">Calculate</button>
                        <button [disabled]="status.status!='4'" [ngClass]="{btndisabled: status.status!='4'}"
                          class="bordered line-hith warn" mat-button (click)="stopprocess(gisdata,'direction')">Stop
                          Process</button>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container> -->
                                <!--  -->
