import { DatePipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import {
  initialSharedModel,
  SharedModel,
} from "../../../sharedmodel/shared.model";

@Component({
  selector: "app-gis-data-table",
  templateUrl: "./gis-data-table.component.html",
  styleUrls: ["./gis-data-table.component.scss"],
  providers: [DatePipe], // Add DatePipe as a provider
})
export class GisDataTableComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() columns: string[] = [];
  @Input() buttons: any = {}; // Input for buttons configuration
  @Input() getStatusClass: (status: any) => string;
  @Input() totalCount: number;
  @Input() reportLoader: boolean = false;
  @Output() pageChange = new EventEmitter<any>();
  @Output() selectedRowsChange = new EventEmitter<any[]>(); // Output for selected rows
  sharedModel: SharedModel = { ...initialSharedModel };
  pageSizeOptions: number[] = [10, 25, 50, 100];
  defaultPageSize: number = 10;
  pageIndex: number = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selectedRows: any[] = []; // Track selected rows
  constructor(private datePipe: DatePipe, private cdr: ChangeDetectorRef) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.updateTableData(changes['data'].currentValue);
    }
  }
  ngOnInit(): void {
    this.updateTableData(this.data);
  }
  // Function to handle disabled state
  isButtonDisabled(element: any, buttonLabel: string): boolean {
    const status = element?.status;

    if (buttonLabel === "Accept Log") {
      // Enable 'Accept Log' button only for Accepted status
      return status !== 2;
    }

    if (buttonLabel === "Stop Process") {
      // Show 'Stop Process' button only for Validating status
      return status !== 3;
    }
    if (buttonLabel === "download") {
      // Enable the download button only for status 2
      return status !== 2;
    }
    // Check based on status for other buttons
    if (status === 1) {
      // Status is valid, buttons should not be disabled
      return false;
    } else if (
      status === 2 ||
      status === -2 ||
      status === -1 ||
      status === 0 ||
      status === 3 ||
      status === -3
    ) {
      // Status is accepted, accept-invalid, invalid, or validating -> disable all buttons
      return true;
    }

    return false;
  }

  /**
   * Checks if a value is in a valid date format using regex.
   */
  isDate(value: any): boolean {
    // Regex to match date formats like "YYYY-MM-DD HH:MM:SS" or similar
    const dateRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}(\.\d+)?$/;
    return typeof value === "string" && dateRegex.test(value);
  }

  /**
   * Formats the value for display.
   * If the value is a date, it formats it to 'd MMM yyyy HH:mm:ss'.
   * Limits to 25 characters and adds ellipsis if needed.
   * Replaces null, undefined, or empty values with a dash.
   */
  formatDisplayValue(value: any): string {
    if (value === null || value === undefined || value === "") {
      return "--";
    }
    if (this.isDate(value)) {
      // Format the date using Angular's DatePipe
      return this.datePipe.transform(value, "d MMM yyyy HH:mm:ss") || "--";
    }
    return value.toString().length > 20
      ? value.toString().slice(0, 20) + "..."
      : value.toString();
  }

  /**
   * Formats the value for the tooltip.
   * Replaces null, undefined, or empty values with a dash.
   */
  formatValue(value: any): string {
    return value === null || value === undefined || value === ""
      ? "--"
      : value.toString();
  }

  /**
   * Checks if a tooltip is needed based on the length of the value.
   */
  isTooltipNeeded(value: any): boolean {
    return value && value.toString().length > 25;
  }
  pageAction(e: PageEvent) {
    this.pageIndex = e.pageIndex;
    if (this.sharedModel.limit !== e.pageSize) {
      this.sharedModel.limit = e.pageSize;
      this.sharedModel.offset = 0;
      this.paginator.pageIndex = 0;
    } else {
      this.sharedModel.offset = this.pageIndex * e.pageSize;
    }
    const pagedetails = {
      limit: this.sharedModel.limit,
      offset: this.sharedModel.offset,
    
    };
    this.pageChange.emit(pagedetails);
    this.updateTableData(this.data);
  }
  // Function to handle individual row checkbox
  toggleRow(element: any): void {
    const index = this.selectedRows.indexOf(element);
    if (index === -1) {
      this.selectedRows.push(element);
    } else {
      this.selectedRows.splice(index, 1);
    }
    this.selectedRowsChange.emit(this.selectedRows);
  }

  // Function to check if a row is selected
  isRowSelected(element: any): boolean {
    return this.selectedRows.indexOf(element) !== -1;
  }

  // Function to handle "select all" checkbox in header
  toggleAllRows(event: any): void {
    if (event.checked) {
      // Select all rows in the current data
      this.selectedRows = [...this.data];
    } else {
      // Clear all selections
      this.selectedRows = [];
    }
    this.selectedRowsChange.emit(this.selectedRows);
  }

  // Check if all rows are selected
  isAllSelected(): boolean {
    return (
      this.data.length > 0 && this.selectedRows.length === this.data.length
    );
    // return this.selectedRows.length === this.data.length;
  }

  // Check if some rows are selected
  isSomeSelected(): boolean {
    // return this.selectedRows.length > 0 && this.selectedRows.length < this.data.length;
    return (
      this.data.length > 0 &&
      this.selectedRows.length > 0 &&
      this.selectedRows.length < this.data.length
    );
  }

  updateTableData(newData: any[]): void {
    this.data = Array.isArray(newData) ? newData : [];
    this.selectedRows = []; // Clear all selections
    this.selectedRowsChange.emit(this.selectedRows); // Notify changes

    this.cdr.detectChanges(); // Ensure UI updates are applied
  }
  resetCheckboxState(): void {
    // Uncheck the "Select All" checkbox by clearing selectedRows
    this.selectedRows = [];
    this.selectedRowsChange.emit(this.selectedRows); // Notify changes

    this.cdr.detectChanges(); // Ensure UI updates
  }
}
