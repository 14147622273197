<form [formGroup]="fileUploadForm">
  <div fxLayout="row" fxLayoutAlign="start center" class="upload-container flex-wrap">
    <div class="upload-label">
      <h3>{{ title }}</h3>
    </div>
    <div class="upload-input">
      <input
        type="file"
        name="myfile"
        #myFileInput
        (change)="handleFileInput($event)"
        formControlName="file"
        accept=".zip"
      />
      <span class="upload-file"
        >Drop items here or <strong>Browse files</strong></span
      >
      <!-- File name with remove icon -->
      <div *ngIf="fileName" class="file-details">
        <p class="url">
          {{
            fileName.length > 30
              ? (fileName | slice : 0 : 30) + "..."
              : fileName
          }}
        </p>
        <mat-icon (click)="reset()" class="remove-icon">cancel</mat-icon>
      </div>
    </div>

    <div class="upload-button">
      <button
        (click)="uploadFile()"
        mat-button
        class="orange lean-radius"
        [disabled]="uploadLoading"
      >
        <span *ngIf="!uploadLoading">Upload</span>
        <span *ngIf="uploadLoading">Uploading...</span>
      </button>
    </div>
  </div>

  <!-- Loading indicator -->
  <ngx-loading
    [show]="uploadLoading"
    [config]="{ backdropBackgroundColour: 'rgba(255,255,255,0.3)' }"
  ></ngx-loading>
</form>
