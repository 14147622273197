import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from "moment";
import { MY_FORMATS } from '../../../../../../src/app/admin/spark-calculate-sync/spark-calculate-sync.component';

@Component({
  selector: 'app-year-picker',
  templateUrl: './year-picker.component.html',
  styleUrls: ['./year-picker.component.scss'],
    providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class YearPickerComponent implements OnInit{
  @Input() startYear: number = 2000;  // Input for minimum year
  @Input() currentYear: number = new Date().getFullYear();  // Input for maximum year
  @Output() yearSelected = new EventEmitter<number>();  // Output event to emit the selected year
  selectedYear: number = _moment().year();  // Store the selected year
  date = new FormControl(_moment());  // Form control for handling the selected date

  constructor() {}

  ngOnInit(): void {}

  chosenYearHandler(normalizedYear: _moment.Moment, dp: any) {
    const ctrlValue = this.date.value as _moment.Moment;  // Ensure moment type
    if (ctrlValue && normalizedYear && normalizedYear.isValid()) {
      ctrlValue.year(normalizedYear.year());
      this.date.setValue(ctrlValue);
      this.selectedYear = ctrlValue.year();   // Update the selected year
      this.yearSelected.emit(this.selectedYear);  // Emit the year
      dp.close();
    } else {
      console.error('Invalid year selection');
    }
  }

}
