<div class="parent-container">
    <mat-card appearance="outlined" class="example-card">
        <mat-card-header>
            <mat-card-title class="master-title title open-sans">Calculate/Sync</mat-card-title>
            <mat-select class="year-dropdown" [(ngModel)]="selectedYear" placeholder="Year" (selectionChange)="setGlobleYear()">
                <div class="search_bar">
                        <input mat-input [(ngModel)]="search_year" [ngModelOptions]="{standalone: true}" placeholder="Search..." (keydown)="$event.stopPropagation();" />
                </div>
                <mat-option *ngFor="let year of yearList | searchfilter:'val':search_year" [value]="year.val">
                    {{year.val}}</mat-option>
            </mat-select>
        </mat-card-header>
        <mat-card-content>
            <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
            <div class="gis_data_form">
                <form [formGroup]="calculateSyncForm">
                    <div class="bottom-border">
                        <mat-radio-group [(ngModel)]="selected" [ngModelOptions]="{standalone: true}" (change)="selectedForm($event.value)">
                            <mat-radio-button class="radio_text" *ngFor="let option of options" [value]="option.value">
                                {{option.label}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <!-- highway facing -->
                    <div *ngIf="formValue == 'highway_facing'" fxLayout="row" fxLayoutAlign="space-between center" class="flex-wrap">
                        <div style="padding-left: 10px;">
                            <mat-form-field class="mr-2">
                                <mat-select (selectionChange)="selectedNeigh($event.value)" placeholder="Select Neighbourhood *" formControlName="neigh" (openedChange)="searchNeigh = ''">
                                    <input class="searchIn" mat-input [(ngModel)]="searchNeigh" [ngModelOptions]="{standalone: true}" placeholder="Type name here to search..." (keydown)="$event.stopPropagation();" />
                                    <!-- <mat-option [value]='' (click)="searchNeigh=''">Select Neighbourhood</mat-option> -->
                                    <mat-option *ngFor="let list of neighbourhoods | neighFilter:searchNeigh" [value]="list.neigh_uid" (click)="searchNeigh=''">
                                        {{ list.neigh_name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <input matInput type="number" [(ngModel)]="distance" [ngModelOptions]="{standalone: true}" placeholder="Enter Distance (in m) *" formControlName="distance">
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <mat-select placeholder="Refresh Calculation" (selectionChange)="selectedRefresh($event.value)" formControlName="refresh" (openedChange)="searchBlock = ''">
                                    <mat-option [value]=''>Select Refresh</mat-option>
                                    <mat-option *ngFor="let option of refresh" [value]="option.value">
                                        {{ option.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <mat-select multiple placeholder="Ex Zoning *" formControlName="zoning">
                                    <div class="search-box">
                                        <input type="text" [(ngModel)]="search_value"  [ngModelOptions]="{standalone: true}" ><br>
                                        <mat-checkbox (change)="selectAll($event)" [checked]="isCheckedAll()" >Select All</mat-checkbox>
                                    </div>
                                    <mat-option #matOption *ngFor="let name of ZoningList | searchfilter: null : search_value" [value]="name"
                                    (click)="selectOne(matOption)" >
                                        {{ name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <mat-select multiple placeholder="Ex Property subcat *" formControlName="propertysubcat">
                                    <div class="search-box">
                                        <input type="text" [(ngModel)]="search_subcat"  [ngModelOptions]="{standalone: true}" ><br>
                                        <mat-checkbox (change)="selectSubcatAll($event)" [checked]="isCheckedSubcatAll()" >Select All</mat-checkbox>
                                    </div>
                                    <mat-option #matOptionsub *ngFor="let name of propertySubcatList | searchfilter: null : search_subcat" [value]="name"
                                    (click)="selectSubOne(matOptionsub)">
                                        {{ name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>
                        <div>
                            <!-- <button mat-raised-button color="primary" (click)="openConfirmation()" matTooltip="Setback calculation">
              </button> -->
                            <button mat-raised-button color="primary" [disabled]="loader" (click)="openConfirmation()" matTooltip="Calculate" style="margin-right: 20px;">
                Calculate
              </button>
                        </div>
                    </div>
                    <!--  -->
                    <!-- parcel clusters -->
                    <div *ngIf="formValue == 'cluster'" fxLayout="row" fxLayoutAlign="space-between center" class="d-flex justify-content-start align-items-center flex-wrap">
                        <div style="padding-left: 25px;">
                            <mat-form-field class="mr-2">
                                <mat-select (selectionChange)="selectedNeigh($event.value)" placeholder="Select Neighbourhood *" formControlName="neigh" (openedChange)="searchNeigh = ''">
                                    <input class="searchIn" mat-input [(ngModel)]="searchNeigh" [ngModelOptions]="{standalone: true}" placeholder="Type Name here to search..." (keydown)="$event.stopPropagation();" />
                                    <!-- <mat-option [value]='' (click)="searchNeigh=''">Select Neighbourhood</mat-option> -->
                                    <mat-option *ngFor="let list of neighbourhoods | neighFilter:searchNeigh" [value]="list.neigh_uid" (click)="searchNeigh=''">
                                        {{ list.neigh_name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <mat-select placeholder="Select Block" (selectionChange)="selectBlock($event.value)" formControlName="block" (openedChange)="searchBlock = ''">
                                    <input class="searchIn" mat-input [(ngModel)]="searchBlock" [ngModelOptions]="{standalone: true}" placeholder="Type Name here to search..." (keydown)="$event.stopPropagation();" />
                                    <mat-option [value]='' (click)="searchBlock = ''">All</mat-option>
                                    <mat-option *ngFor="let block of blocks | searchfilter:'block_name':searchBlock" [value]="block.block_uid">
                                        {{ block.block_name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <button mat-raised-button color="primary" (click)="openConfirmation()" [disabled]="loader" matTooltip="Calculate Parcel Cluster" style="margin-right: 20px;">
                Calculate
              </button>
                        </div>
                    </div>
                    <!--  -->
                    <!-- sync attributes -->
                    <div *ngIf="formValue == 'sync attribute'" fxLayout="row" fxLayoutAlign="space-between center" class="d-flex justify-content-start align-items-center flex-wrap">
                        <div style="padding-left: 25px;">
                            <mat-form-field class="mr-2">
                                <mat-select [(ngModel)]="attributeName" [ngModelOptions]="{standalone: true}" placeholder="Select Attibute *" formControlName="attrName">
                                    <input class="searchIn" mat-input [(ngModel)]="searchAttr" [ngModelOptions]="{standalone: true}" placeholder="Type name here to search..." (keydown)="$event.stopPropagation();" />
                                    <mat-option [value]=''>Select Attribute</mat-option>
                                    <mat-option *ngFor="let list of gisAttributes | gisAttribute:searchAttr" [value]="list.attribute_name">
                                        {{list.attribute_name}}
                                    </mat-option>
                                </mat-select>
                                <!-- <input type="text" matInput [(ngModel)]="attrName" placeholder="Enter Attribute Name"
                  formControlName="attrName"> -->
                                <!-- <mat-error>Enter an Attribute Name</mat-error> -->
                            </mat-form-field>
                        </div>
                        <div>
                            <button mat-raised-button color="primary" (click)="sync(calculateSyncForm)" [disabled]="loader" matTooltip="Sync Attribute Data" style="margin-right: 20px;">
                Sync
              </button>
                        </div>
                    </div>
                    <!--  -->
                    <!-- amenty distance -->
                    <div *ngIf="formValue == 'amenity distance'" fxLayout="row" fxLayoutAlign="space-between center" class="d-flex justify-content-start align-items-center flex-wrap">
                        <div style="padding-left: 25px;">
                            <mat-form-field class="mr-2">
                                <mat-select (selectionChange)="selectedNeigh($event.value)" placeholder="Select Neighbourhood *" formControlName="neigh" (openedChange)="searchNeigh = ''">
                                    <input class="searchIn" mat-input [(ngModel)]="searchNeigh" [ngModelOptions]="{standalone: true}" placeholder="Type name here to search..." (keydown)="$event.stopPropagation();" />
                                    <!-- <mat-option [value]='' (click)="searchNeigh=''">Select Neighbourhood</mat-option> -->
                                    <mat-option *ngFor="let list of neighbourhoods | neighFilter:searchNeigh" [value]="list.neigh_uid" (click)="searchNeigh=''">
                                        {{ list.neigh_name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <mat-select placeholder="Select Block" (selectionChange)="selectBlock($event.value)" formControlName="block" (openedChange)="searchBlock = ''">
                                    <input class="searchIn" mat-input [(ngModel)]="searchBlock" [ngModelOptions]="{standalone: true}" placeholder="Type Name here to search..." (keydown)="$event.stopPropagation();" />
                                    <mat-option [value]='' (click)="searchBlock=''">All</mat-option>
                                    <mat-option *ngFor="let block of blocks | searchfilter:'block_name':searchBlock" [value]="block.block_uid" (click)="searchBlock =''">
                                        {{ block.block_name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <input matInput type="text" [(ngModel)]="buildingUid" [ngModelOptions]="{standalone: true}" placeholder="Enter Building Uids" formControlName="buildingUid">
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <!-- <input matInput type="text" [(ngModel)]="subCat" [ngModelOptions]="{standalone: true}" placeholder="Enter Sub Categories *" formControlName="subCat"> -->
                                <mat-select multiple placeholder="Select Sub Categories" [(ngModel)]="subCat" [ngModelOptions]="{standalone: true}" formControlName="subCat">
                                    <!-- <input class="searchIn" mat-input [(ngModel)]="subCat" [ngModelOptions]="{standalone: true}" placeholder="search..." (keydown)="$event.stopPropagation();" /> -->
                                    <!-- <mat-option [value]='' (click)="subCat=''">Select Block</mat-option> -->
                                    <mat-option *ngFor="let subCat of sub_categories" [value]="subCat">
                                        {{ subCat }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <input matInput type="number" [(ngModel)]="radius" [ngModelOptions]="{standalone: true}" placeholder="Enter Radius *" formControlName="radius">
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <mat-select placeholder="Refresh Calculation *" (selectionChange)="selectedRefresh($event.value)" formControlName="refresh">
                                    <mat-option [value]=''>Select Refresh</mat-option>
                                    <mat-option *ngFor="let option of refresh" [value]="option.value">
                                        {{ option.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <button mat-raised-button color="primary" (click)="openConfirmation()" [disabled]="loader" matTooltip="Calculate Amenity Distance" style="margin-right: 20px;">
                Calculate
              </button>
                        </div>
                    </div>
                    <!--  -->
                    <!-- direction info -->
                    <div *ngIf="formValue == 'direction'" fxLayout="row" fxLayoutAlign="space-between center" class="d-flex justify-content-start align-items-center flex-wrap">
                        <div style="padding-left: 25px;">
                            <mat-form-field class="mr-2">
                                <mat-select (selectionChange)="selectedNeigh($event.value)" placeholder="Select Neighbourhood *" formControlName="neigh" (openedChange)="searchNeigh = ''">
                                    <input class="searchIn" mat-input [(ngModel)]="searchNeigh" [ngModelOptions]="{standalone: true}" placeholder="Type here to search..." (keydown)="$event.stopPropagation();" />
                                    <!-- <mat-option [value]='' (click)="searchNeigh = ''">Select Neighbourhood</mat-option> -->
                                    <mat-option *ngFor="let list of neighbourhoods | neighFilter:searchNeigh" [value]="list.neigh_uid" (click)="searchNeigh = ''">
                                        {{ list.neigh_name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <mat-select placeholder="Select Block" (selectionChange)="selectBlock($event.value)" formControlName="block" (openedChange)="searchBlock = ''">
                                    <input class="searchIn" mat-input [(ngModel)]="searchBlock" [ngModelOptions]="{standalone: true}" placeholder="Type Name here to search..." (keydown)="$event.stopPropagation();" />
                                    <mat-option [value]='' (click)="searchBlock = ''">All</mat-option>
                                    <mat-option *ngFor="let block of blocks | searchfilter:'block_name':searchBlock" [value]="block.block_uid" (click)="searchBlock = ''">
                                        {{ block.block_name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <input matInput type="text" [(ngModel)]="parcel" [ngModelOptions]="{standalone: true}" placeholder="Enter Parcel Uids" formControlName="parcel">
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <input matInput type="text" [(ngModel)]="cluster" [ngModelOptions]="{standalone: true}" formControlName="clusterGids" placeholder="Enter Cluster Gids">
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <mat-select placeholder="Refresh Calculation *" (selectionChange)="selectedRefresh($event.value)" formControlName="refresh">
                                    <mat-option [value]=''>Select Refresh</mat-option>
                                    <mat-option *ngFor="let option of refresh" [value]="option.value">
                                        {{ option.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <!-- <button mat-raised-button color="primary" (click)="openConfirmation()" matTooltip="Setback calculation">
              </button> -->
                            <button mat-raised-button color="primary" (click)="openConfirmation()" [disabled]="loader" matTooltip="Calculate" style="margin-right: 20px;">
                Calculate
              </button>
                        </div>
                    </div>
                    <!--  -->
                    <!-- setback info -->
                    <div *ngIf="formValue == 'setback'" fxLayout="row" fxLayoutAlign="space-between center" class="d-flex justify-content-start align-items-center flex-wrap">
                        <div style="padding-left: 25px;">
                            <mat-form-field class="mr-2">
                                <mat-select (selectionChange)="selectedNeigh($event.value)" placeholder="Select Neighbourhood *" formControlName="neigh" (openedChange)="searchNeigh = ''">
                                    <input class="searchIn" mat-input [(ngModel)]="searchNeigh" [ngModelOptions]="{standalone: true}" placeholder="Type name here to search..." (keydown)="$event.stopPropagation();" />
                                    <!-- <mat-option [value]='' (click)="searchNeigh = ''">Select Neighbourhood</mat-option> -->
                                    <mat-option *ngFor="let list of neighbourhoods | neighFilter:searchNeigh" [value]="list.neigh_uid" (click)="searchNeigh = ''">
                                        {{ list.neigh_name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <mat-select placeholder="Select Block" (selectionChange)="selectBlock($event.value)" formControlName="block" (openedChange)="searchBlock = ''">
                                    <input class="searchIn" mat-input [(ngModel)]="searchBlock" [ngModelOptions]="{standalone: true}" placeholder="Type Name here to search..." (keydown)="$event.stopPropagation();" />
                                    <mat-option [value]='' (click)="searchBlock = ''">All</mat-option>
                                    <mat-option *ngFor="let block of blocks | searchfilter:'block_name':searchBlock" [value]="block.block_uid" (click)="searchBlock = ''">
                                        {{ block.block_name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <input matInput type="text" [(ngModel)]="parcel" [ngModelOptions]="{standalone: true}" placeholder="Enter Parcel Uids" formControlName="parcel">
                            </mat-form-field>
                        </div>
                        <div>
                            <!-- <button mat-raised-button color="primary" (click)="openConfirmation()" matTooltip="Setback calculation">
              </button> -->
                            <button mat-raised-button color="primary" (click)="openConfirmation()" [disabled]="loader" matTooltip="Calculate" style="margin-right: 20px;">
                Calculate
              </button>
                        </div>
                    </div>
                    <!--  -->
                    <!-- highway access -->
                    <div *ngIf="formValue == 'highway rays'" fxLayout="row" fxLayoutAlign="space-between center" class="d-flex justify-content-start align-items-center flex-wrap">
                        <div style="padding-left: 25px;">
                            <mat-form-field class="mr-2">
                                <mat-select (selectionChange)="selectedNeigh($event.value)" placeholder="Select Neighbourhood *" formControlName="neigh" (openedChange)="searchNeigh = ''">
                                    <input class="searchIn" mat-input [(ngModel)]="searchNeigh" [ngModelOptions]="{standalone: true}" placeholder="Type here to search..." (keydown)="$event.stopPropagation()" />
                                    <!-- <mat-option [value]='' (click)="searchNeigh = ''">Select Neighbourhood</mat-option> -->
                                    <mat-option *ngFor="let list of neighbourhoods | neighFilter:searchNeigh" [value]="list.neigh_uid">
                                        {{ list.neigh_name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <mat-select placeholder="Select Block" (selectionChange)="selectBlock($event.value)" formControlName="block" (openedChange)="searchBlock = ''">
                                    <input class="searchIn" mat-input [(ngModel)]="searchBlock" [ngModelOptions]="{standalone: true}" placeholder="Type Name here to search..." (keydown)="$event.stopPropagation();" />
                                    <mat-option [value]='' (click)="searchBlock = ''">All</mat-option>
                                    <mat-option *ngFor="let block of blocks | searchfilter:'block_name':searchBlock" [value]="block.block_uid" (click)="searchBlock = ''">
                                        {{ block.block_name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <input matInput type="text" [(ngModel)]="parcel" [ngModelOptions]="{standalone: true}" placeholder="Enter Parcel Uids" formControlName="parcel">
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <input matInput type="number" [(ngModel)]="radius" [ngModelOptions]="{standalone: true}" placeholder="Enter Radius (in m) *" formControlName="radius">
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <input matInput type="number" [(ngModel)]="degree" [ngModelOptions]="{standalone: true}" placeholder="Enter Degree(s) *" formControlName="degree">
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <mat-select placeholder="Refresh Calculation *" (selectionChange)="selectedRefresh($event.value)" formControlName="refresh">
                                    <mat-option [value]=''>Select Refresh</mat-option>
                                    <mat-option *ngFor="let option of refresh" [value]="option.value">
                                        {{ option.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <!-- <button mat-raised-button color="primary" (click)="openConfirmation()" matTooltip="Setback calculation">
                </button> -->
                            <button mat-raised-button color="primary" (click)="openConfirmation()" [disabled]="loader" matTooltip="Calculate" style="margin-right: 20px;">
                Calculate
              </button>
                        </div>
                    </div>
                    <!-- parcel Visibility -->
                    <div *ngIf="formValue == 'parcel_visibility'" fxLayout="row" fxLayoutAlign="space-between center" class="d-flex justify-content-start align-items-center flex-wrap">
                        <div style="padding-left: 25px;">
                            <mat-form-field class="mr-2">
                                <mat-select (selectionChange)="selectedNeigh($event.value)" placeholder="Select Neighbourhood *" formControlName="neigh">
                                    <input class="searchIn" mat-input [(ngModel)]="searchNeigh" [ngModelOptions]="{standalone: true}" placeholder="Type here to search..." (keydown)="$event.stopPropagation();" />
                                    <!-- <mat-option [value]=''>Select Neighbourhood</mat-option> -->
                                    <mat-option *ngFor="let list of neighbourhoods | neighFilter:searchNeigh" [value]="list.neigh_uid">
                                        {{ list.neigh_name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <mat-select placeholder="Select Block" (selectionChange)="selectBlock($event.value)" formControlName="block" (openedChange)="searchBlock ='' ">
                                    <input class="searchIn" mat-input [(ngModel)]="searchBlock" [ngModelOptions]="{standalone: true}" placeholder="Type Name here to search..." (keydown)="$event.stopPropagation();" />
                                    <mat-option [value]=''>All</mat-option>
                                    <mat-option *ngFor="let block of blocks | searchfilter:'block_name':searchBlock" [value]="block.block_uid">
                                        {{ block.block_name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <input matInput type="text" [(ngModel)]="parcel" [ngModelOptions]="{standalone: true}" placeholder="Enter Parcel" formControlName="parcel">
                            </mat-form-field>
                            <!-- <mat-form-field class="mr-2">
                <mat-select placeholder="Refresh Calculation" (selectionChange)="selectedRefresh($event.value)"
                  formControlName="refresh">
                  <mat-option [value]=''>Select Refresh</mat-option>
                  <mat-option *ngFor="let option of refresh" [value]="option.value">
                    {{ option.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->
                        </div>
                        <div>
                            <!-- <button mat-raised-button color="primary" (click)="openConfirmation()" matTooltip="Setback calculation">
              </button> -->
                            <button mat-raised-button color="primary" (click)="openConfirmation()" [disabled]="loader" matTooltip="Calculate" style="margin-right: 20px;">
                Calculate
              </button>
                        </div>
                    </div>
                    <!--sikka visiblity-->
                    <div *ngIf="formValue == 'sikka_parcel'" fxLayout="row" fxLayoutAlign="space-between center" class="d-flex justify-content-start align-items-center flex-wrap">
                        <div style="padding-left: 25px;">
                            <mat-form-field class="mr-2">
                                <mat-select (selectionChange)="selectedNeigh($event.value)" placeholder="Select Neighbourhood *" formControlName="neigh">
                                    <input class="searchIn" mat-input [(ngModel)]="searchNeigh" [ngModelOptions]="{standalone: true}" placeholder="Type here to search..." (keydown)="$event.stopPropagation();" />
                                    <!-- <mat-option [value]=''>Select Neighbourhood</mat-option> -->
                                    <mat-option *ngFor="let list of neighbourhoods | neighFilter:searchNeigh" [value]="list.neigh_uid">
                                        {{ list.neigh_name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <mat-select placeholder="Select Block" (selectionChange)="selectBlock($event.value)" formControlName="block" (openedChange)="searchBlock = '' ">
                                    <input class="searchIn" mat-input [(ngModel)]="searchBlock" [ngModelOptions]="{standalone: true}" placeholder="Type Name here to search..." (keydown)="$event.stopPropagation();" />
                                    <mat-option [value]=''>All</mat-option>
                                    <mat-option *ngFor="let block of blocks | searchfilter:'block_name':searchBlock" [value]="block.block_uid">
                                        {{ block.block_name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="mr-2">
                                <input matInput type="text" [(ngModel)]="parcel" [ngModelOptions]="{standalone: true}" placeholder="Enter Parcel Uids" formControlName="parcel">
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <input matInput type="number" [(ngModel)]="radius" [ngModelOptions]="{standalone: true}"placeholder="Enter Width *" formControlName="radius">
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <input matInput type="text" [(ngModel)]="zoning" [ngModelOptions]="{standalone: true}" placeholder="Enter Zonings" formControlName="zoning">
                            </mat-form-field>
                            <!-- <mat-form-field class="mr-2">
                <mat-select placeholder="Refresh Calculation" (selectionChange)="selectedRefresh($event.value)"
                  formControlName="refresh">
                  <mat-option [value]=''>Select Refresh</mat-option>
                  <mat-option *ngFor="let option of refresh" [value]="option.value">
                    {{ option.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->
                        </div>
                        <div>
                            <!-- <button mat-raised-button color="primary" (click)="openConfirmation()" matTooltip="Setback calculation">
              </button> -->
                            <button mat-raised-button color="primary" (click)="openConfirmation()"[disabled]="loader" matTooltip="Calculate" style="margin-right: 20px;">
                Calculate
              </button>
                        </div>
                    </div>
                </form>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined" class="table-card">
        <mat-card-header fxLayout="row" fxLayoutAlign="space-between center" style="overflow: auto;" class="d-flex justify-content-start align-items-center flex-wrap">
            <mat-card-title class="master-title title open-sans">Calculate/Sync</mat-card-title>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="flex-wrap">
                <div class="filter mr-3" *ngIf="this.filter && process_name!='sync attribute'">
                    <span class="open-sans mr-1">Filter By Neighbourhood:</span>
                    <mat-select *ngIf="reset==''" class="filter-by" placeholder="Select Neighbourhood" (selectionChange)="filterByStatus($event.value,'neigh')">
                        <!-- <mat-option value=''>Select Filter</mat-option> -->
                        <input class="searchIn" mat-input [(ngModel)]="searchNeigh" [ngModelOptions]="{standalone: true}" placeholder="Type here to search..." (keydown)="$event.stopPropagation();" />
                        <mat-option value='All'>All</mat-option>
                        <mat-option *ngFor="let list of filteredNeighs | neighFilter: searchNeigh" [value]="list.uid">
                            {{ list.layer }}
                        </mat-option>
                    </mat-select>
                </div>
                <div class="filter mr-3" *ngIf="this.filter && process_name=='sync attribute'">
                    <span class="open-sans mr-1">Filter By Attribute:</span>
                    <mat-select *ngIf="reset==''" class="filter-by" placeholder="Select Attribute" (selectionChange)="filterByStatus($event.value,'attribute')">
                        <!-- <mat-option value=''>Select Filter</mat-option> -->
                        <input class="searchIn" mat-input [(ngModel)]="searchNeigh" [ngModelOptions]="{standalone: true}" placeholder="Type here to search..." (keydown)="$event.stopPropagation();" />
                        <mat-option value='All'>All</mat-option>
                        <mat-option *ngFor="let list of filteredAttributes | neighFilter: searchNeigh" [value]="list.layer">
                            {{ list.layer }}
                        </mat-option>
                    </mat-select>
                </div>
                <div class="filter ml-3" *ngIf="this.filter">
                    <span class="open-sans mr-1">Status:</span>
                    <mat-select *ngIf="reset==''" class="filter-by" placeholder="Select Status" (selectionChange)="filterByStatus($event.value,'status')">
                        <!-- <mat-option value=''>Select Filter</mat-option> -->
                        <mat-option value='All'>All</mat-option>
                        <mat-option *ngFor="let value of reportStatus" [value]="value.uid">
                            {{getStatus(value.uid)}}
                        </mat-option>
                    </mat-select>
                </div>

            </div>
        </mat-card-header>
        <mat-card-content class="tabs">
            <ngx-loading [show]="loader1" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
            <div class="bottom-border">
                <button class="margr5" *ngFor="let option of options" (click)="getReports(option.value,'button')" [ngClass]="{'active': getActive_(option)}" style="white-space: nowrap;">
                    {{option.label}}</button>
            </div>
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource" matSort class="w-100 data-table table">
                    <ng-container *ngFor="let cols of displayedColumns">
                        <ng-container matColumnDef="{{ cols }}">
                            <ng-container *ngIf="cols=='id' || cols=='uid'">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                    <span class="header action cp" *ngIf="cols == 'id'"> Upload ID </span>
                                    <span class="header action cp" *ngIf="cols == 'uid'"> Name</span>
                                </th>
                            </ng-container>
                            <ng-container *ngIf="cols!='id' || cols!='uid'">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span *ngIf="cols == 'sno'">S.No</span>
                                    <span *ngIf="cols!='sno'" class="header action cp">{{ cols }}</span>
                                </th>
                            </ng-container>
                            <td mat-cell *matCellDef="let element; let i=index;">
                                <span *ngIf="cols == 'sno'"> {{offset+i+1}}.</span>
                                <span [ngClass]="{green: getStatus(element.status)=='Accepted'}" *ngIf="cols=='Status' && getStatus(element.status)=='Accepted'">{{getStatus(element.status)}}</span>
                                <span [ngClass]="{red: getStatus(element.status)=='Rejected'}" *ngIf="cols=='Status' && getStatus(element.status)=='Rejected'">{{getStatus(element.status)}}</span>
                                <span [ngClass]="{red: getStatus(element.status)=='Invalid'}" *ngIf="cols=='Status' && getStatus(element.status)=='Invalid'">{{getStatus(element.status)}}</span>
                                <span *ngIf="cols=='Status' && getStatus(element.status)!='Invalid'   && getStatus(element.status)!='Rejected'  && getStatus(element.status)!='Accepted'">{{getStatus(element.status)}}</span>
                                <ng-container *ngIf="selectedlayer == 'highway_facing'">
                                    <p class="ellipsis" *ngIf="cols == 'Zoning'" title="{{getLabel(element.zoning)}}">{{getLabel(element.zoning)}}</p>
                                    <p class="ellipsis" *ngIf="cols == 'Subcat'" title="{{getLabel(element.property_sub_cat)}}">{{getLabel(element.property_sub_cat)}}</p>
                                </ng-container>
                                <div>
                                <a class="view" *ngIf="cols == 'Error File'" href="{{element.log_file}}" target="_blank" [disabled]="element.err_file==null">Error Log</a>
                                <a class="view process_log" *ngIf="cols == 'Error File'" (click)="download_process_log(element.layer,element.process_name,element.id)">Process Log</a>
                                </div>
                                <a class="view" *ngIf="cols == 'Log File'" href="{{element.err_file}}" target="_blank" [disabled]="element.log_file==null">Log</a>
                                <div *ngIf="cols=='Actions'" class="d-flex justify-content-start align-items-center">
                                    <button [ngClass]="{disabled: getStatus(element.status)!='Start'}" [disabled]="getStatus(element.status)!='Start'" class="grey-button pointer d-flex justify-content-center align-items-center"
                                    (click)="stopProcess(element.id)">
                                    <span class="material-icons">highlight_off</span> <span> STOP PROCESS</span></button>
                                </div>
                                <p matTooltip={{returnVal(element,cols)}} *ngIf="cols != 'sno' && cols != 'Parameter' && cols != 'Process Log' && cols != 'Status'" class="more mb-0">
                                    {{returnVal(element, cols)}}</p>

                            </td>
                        </ng-container>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
                </table>
            </div>
            <mat-paginator [length]="totalCount" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = $event; handlePagination($event)">
            </mat-paginator>


        </mat-card-content>
    </mat-card>
</div>
