<form [formGroup]="copyAttributesGroup">
  <div class="mtc-header p-2 position-relative" fxLayout="row" fxLayoutAlign="center center">
    <h5>Copy Attributes</h5>
  </div>

  <mat-form-field class="p-3">
    <mat-label>Select City</mat-label>
    <mat-select formControlName="city_selection" multiple (selectionChange)="selectionChange()"
      (closed)="clearSearchInput()">
      <mat-select-trigger>
        <mat-chip-set>
          <mat-chip *ngFor="let city of copyAttributesGroup.value.city_selection" [removable]="true"
            (removed)="onCityRemoved(city)">
            {{ city.viewValue }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-set>
      </mat-select-trigger>

      <!-- Search Input -->
      <mat-form-field class="search-field">
        <input matInput #searchValue (input)="filterCities($event.target.value)" placeholder="Search cities">
      </mat-form-field>

      <!-- City Options -->
      <ng-container *ngFor="let city of filteredCities">
        <mat-option *ngIf="selected_city_id != city.value" [value]="city">
          {{ city.viewValue }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <div class="footer gap-20" fxLayout="row" fxLayoutAlign="center center">
    <div>
      <button class="gis-primary-button" (click)="submit()"
        [disabled]="copyAttributesGroup.value.city_selection.length == 0">Paste</button>
    </div>
    <div>
      <button (click)="cancel()" class="gis-secondary-button">Cancel</button>
    </div>
  </div>
</form>

<!-- Loading indicator -->
<ngx-loading [show]="sharedModel.loader" [config]="{ backdropBackgroundColour: 'rgba(255,255,255,0.3)' }"></ngx-loading>
